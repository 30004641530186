import React, {useState} from 'react';
import Slider from 'react-slick';
import {
  bannerImgOne,
  bannerImgTwo,
  bannerImgThree,
  bannerImgFour,
  bannerImgFive,
  bannerImgTen
} from "../../assets";


const Banner = () => {
  const [dotActive, setDotActive] = useState(0);
  const settings = {
  dots: true,
  infinite: true,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  beforeChange: (prev, next) => {
    setDotActive(next);
  },
  appendDots: (dots) => (
    <div
      style={{
        position: "absolute",
        top: "85%",
        left: "0",
        right: "0",
        margin:"0 auto",
        transform: "translate(-50% -50%)",
        width: "210px",
      }}
    >
      <ul style={{ 
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
      >
      {dots} </ul>
    </div>
  ),
  customPaging: i => (
    <div
      style={
        i === dotActive
        ? {
        width: "30px",
        height:"30px",
        color: "white",
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "#ffa721",
        padding: "8px 0",
        cursor: "pointer",
        border: "1px solid #017a3f",
      }
    : {
        width: "30px",
        height:"30px",
        color: "white",
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "#017a3f",
        padding: "8px 0",
        cursor: "pointer",
        border: "1px solid white",
    }
  }
    >
      {i + 1}
    </div>
  ),
  responsive: [
    {
      breakpoint: 576,
      settings: {
        dots: true,
        appendDots: (dots) => (
          <div
            style={{
                position: "absolute",
                top: "70%",
                left: "0",
                right: "0",
                margin: "0 auto",
                transform: "translate(-50% -50%)",
                width: "150px",
            }}
            >
              <ul 
              style={{ 
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
            }}
            >
              {dots}
            </ul>
            </div>
        ),
      customPaging: (i) => (
        <div
      style={
        i === dotActive
        ? {
        width: "20px",
        height:"20px",
        color: "white",
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "#ffa721",
        padding: "8px 0",
        cursor: "pointer",
        border: "1px solid #017a3f",
        fontSize: "12px",
      }
    : {
        width: "20px",
        height:"20px",
        color: "white",
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "#017a3f",
        padding: "8px 0",
        cursor: "pointer",
        border: "1px solid white",
        fontSize: "12px",
    }
  }
    >
      {i + 1}
    </div>
  ),
},
},
],
};
  return (
    <div className='w-full'>
     <div className='w-full h-full relative top-0 mb-[90px]'>
     <Slider {...settings}>
          <div>
          <img className='h-[300px] mdl:h-full' src={bannerImgOne} alt='one'></img>
          </div>
          <div>
          <img className='h-[300px] mdl:h-full' src={bannerImgTwo} alt='two'></img>
          </div>
          <div>
          <img className='h-[300px] mdl:h-full' src={bannerImgThree} alt='three'></img>
          </div>
          <div>
          <img className='h-[300px] mdl:h-full' src={bannerImgTen} alt='four'></img>
          </div>
          <div>
          <img className='h-[300px] mdl:h-full' src={bannerImgFive} alt='five'></img>
          </div>

        </Slider>
      </div>   
      </div>
  )
}

export default Banner