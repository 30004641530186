import React from 'react'
import { useState,useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import {useNavigate} from "react-router-dom";
import {db} from '../firebase.config';
import {storage} from '../firebase.config';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { v4 as uuidv4 } from 'uuid';
import { getStorage, ref, uploadBytesResumable } from "firebase/storage";

const RegisterFarmer = () => {

  const userInfo = useSelector((state) => state.farmlinkReducer.userInfo);

  const [userExists, setUserExists] = useState(false);
  const storage1 = getStorage();

    useEffect(() => {
      if(auth.currentUser){
      const subscriber = db.collection('user').doc(auth.currentUser.uid)
        .onSnapshot((doc) => {
          setUserExists(!doc.empty)
        });
        // return cleanup function
      return () => subscriber();
      }
  
      
    },[]); // empty dependencies array => useEffect only called once

     const navigate = useNavigate();
     const auth = getAuth(); 
     const user = auth.currentUser;
     const [farmerName, setFarmerName]= useState("");
     const [farmerAge, setFarmerAge]= useState(null);
     const [farmerYears, setFarmerYears]= useState(null);
     const [validImg, setValidImg] = useState(null);
     const [validImage, setValidImage] = useState(null);
     const [facePicture, setFacePicture] = useState(null);
     const [faceImage, setFaceImage] = useState(null);
     const [error, setError] = useState('');
     const [successMsg, setSuccessMsg] = useState('')

    
     const [Gnum, setGNum] = useState('');
     const [Cnum, setCNum] = useState('');
     const [CVVnum, setCVVNum] = useState('');
     const [sellerAddress, setSellerAddress] = useState('');
     
     const [gcashNumber, setGcashNumber] = useState('');

     //error
     const [errFarmerName, setErrFarmerName] = useState("");
     const [errFarmerAge, setErrFarmerAge] = useState("");
     const [errFarmerYears, setErrFarmerYears] = useState("");
     const [errValidImage, setErrValidImage] = useState('');
     const [errFacePicture, setErrFacePicture] = useState('');
     const [errSellerAddress, setErrSellerAddress] = useState('');
     const [errSelectedID, setErrSelectedID] = useState('');
     const [firebaseErr, setErrFirebase] = useState('');

     //product image
    const types = ['image/png','image/jpeg','image/jpg'];

  const handleGNumChange = e => {
    const limit = 13;
    setGNum(e.target.value.slice(0, limit));
  };

  const handleCNumChange = event => {
    const limit = 13;
    setCNum(event.target.value.slice(0, limit));
  };
  
  const handleCVVNumChange = event => {
    const limit = 3;
    setCVVNum(event.target.value.slice(0, limit));
  };


const validImgHandler = (e) =>{
  let selectedFile = e.target.files[0];
  if(selectedFile && types.includes(selectedFile.type)){
      setValidImg(selectedFile);
      setValidImage(URL.createObjectURL(e.target.files[0]));
      
      setError('');
  }
  else{
      setValidImg(null);
      setError('Please select a valid image type (png, jpeg, jpg, or pdf)');
  }
}

const facePictureHandler = (e) =>{
  let selectedFile = e.target.files[0];
  if(selectedFile && types.includes(selectedFile.type)){
      setFacePicture(selectedFile);
      setFaceImage(URL.createObjectURL(e.target.files[0]));
      
      setError('');
  }
  else{
      setFacePicture(null);
      setError('Please select a valid image type (png, jpeg, jpg, or pdf)');
  }
}



const handleFarmerRegistration=(e)=>{
    e.preventDefault()
    if(!farmerName){
        setErrFarmerName("Enter your Full Name")
        setErrFirebase("")
    }else{
      setErrFarmerName("");
    }
    if(!validImg){
        setErrValidImage("Upload a Valid Identification");
        setErrFirebase("")
    }else{
      setErrValidImage("");
    }
    if(!farmerAge){
      setErrFarmerAge("Enter your Age");
      setErrFirebase("")
    }else{
      setErrFarmerAge("");
    }
    if(!farmerYears){
    setErrFarmerYears("Enter number of Years");
    setErrFirebase("")
    }else{
      setErrFarmerYears("");
    }
  
    if(!sellerAddress){
      setErrSellerAddress("Enter your Address");
      setErrFirebase("")
  }else{
    setErrSellerAddress("");
  }
  if(!facePicture){
    setErrFacePicture("Upload your Selfie");
    setErrFirebase("")
}else{
  setErrFacePicture("");
}
if(!selectedID){
    setErrSelectedID("Please select ID type");
    setErrFirebase("")
}else{
  setErrSelectedID("");
}
    if(farmerName && validImg && sellerAddress && farmerAge && farmerYears && facePicture && selectedID){
      setSuccessMsg(true)
      const myUuid1 = uuidv4();
      const myUuid2 = uuidv4();
      var metadata = { contentType: 'image/jpeg', };
      const uploadTask = storage.ref(`applicantPictures/${myUuid2}`).put(facePicture);
/*       const storageRef = ref(storage1,`validId/images/${myUuid1}`);
      uploadBytesResumable(storageRef,validImg); */
        uploadTask.on('state_changed', snapshot => {
            const progress = (snapshot.bytesTransferred/snapshot.totalBytes) * 100;
            console.log(progress);
        }, err=> {
            setError(err.message)
        },()=>{
          
          const uploadTask = storage.ref(`validId/images/${myUuid1}`).put(validImg);
          uploadTask.on('state_changed', snapshot => {
            const progress = (snapshot.bytesTransferred/snapshot.totalBytes) * 100;
            console.log(progress);
        }, err=> {
            setError(err.message)
        },()=>{
            storage.ref('applicantPictures/').child(myUuid2).getDownloadURL().then(url2=>{
            storage.ref('validId/images/').child(myUuid1).getDownloadURL().then(url=>{
        db.collection('user').doc(user.uid).collection('sellerInformation').doc('sellerInfoDocument').set({
            age: farmerAge,
            facePicture: url2,
            farmerName: farmerName,
            infoId: auth.currentUser.uid,
            shopLocation: sellerAddress,
            status: "Pending",
            yearsOfFarming: farmerYears,
            idType: selectedID,
            
            validId: [
              url
            ]
            
        })
        .then(()=>{
          db.collection('sellerRequest').doc(auth.currentUser.uid).set({
            age: farmerAge,
            facePicture: url2,
            farmerName: farmerName,
            infoId: auth.currentUser.uid,
            shopLocation: sellerAddress,
            status: "Pending",
            yearsOfFarming: farmerYears,
            idType: selectedID,
            
            validId: [
              url
            ]
            
        })
        setFarmerName("")
        setFarmerAge(null)
        setFarmerYears(null)
        setValidImg(null)
        setValidImage(null)
        setFacePicture(null)
        setFaceImage(null)
        setSellerAddress("")
        setSelectedID("")
        setErrFirebase("")
        setError('');
        

    }).catch(err=>setErrFirebase(err.message));
})
setTimeout(() => {
  alert("Please wait for your registration to be verified!")
  navigate("/profile")
}, 1000);

})
})
})}}



 var count = 0;
 for (const key in Gnum) {

     count++;
 
 }
 var count1 = 0;
 for (const key in Cnum) {

     count1++;
 
 }

 const [selectedID,setSelectedID] = useState('')


  return (
    <div>
      {userExists ? (
    
    <div className='w-full'>

    <div className='mdl:w-full w-[350px] bg-white-100 pb-10 pt-5 ml-2'>
    <form className='w-[300px] mdl:w-[500px] mx-auto flex flex-col items-center'onSubmit={RegisterFarmer}
    id='farmerform'>
    <div className='w-full border border-zinc-200 rounded-tr-md rounded-br-md rounded-tl-md 
    rounded-bl-md p-6 bg-white'>
        <h2 className='font-titleFont text-2xl font-medium mb-4'>{" "}Register as a Selling Farmer</h2>
        <div className='flex flex-col items-center gap-3'>
        
               <img className='rounded-sm w-[100px] h-[100px]'src={faceImage} />
               <input placeholder='' type='file' id='file' accept='image/*,.pdf'className='w-full 
                py-1 border border-zinc-400 px-2 text-base 
               rounded-sm outline-none focus-within:border-farmlink_yellow 
               focus-within:shadow-farmlinkInput duration-100'
               onChange={facePictureHandler}required/>
               {
                        errFacePicture && (
                            <p className='text-red-600 text-xs font-semibold tracking-wide
                            flex items-center gap-2 -mt-1.5'>
                            <span className='italic font-titleFont font-extrabold text-base'>!</span>
                                {errFacePicture}</p>
                        )
                      }
          <div className='flex flex-col gap-2'>

          <p className='text-sm font-bold'>Full Name</p>
          <input placeholder='Enter full Name' type='text' className='w-full py-1 
          border border-zinc-400 px-2 text-base rounded-sm outline-none
          focus-within:border-farmlink_yellow focus-within:shadow-farmlinkInput duration-100' 
          onChange={(e) => setFarmerName(e.target.value)} value={farmerName} required />
            {
                        errFarmerName && (
                            <p className='text-red-600 text-xs font-semibold tracking-wide
                            flex items-center gap-2 -mt-1.5'>
                            <span className='italic font-titleFont font-extrabold text-base'>!</span>
                                {errFarmerName}</p>
                        )
                      }

          <div className='flex flex-row'>
        <div className='flex flex-col'>
          <p className='text-sm font-bold'>Age</p>
          <input placeholder='Enter age' type='number' className='w-[120px] py-1 
          border border-zinc-400 px-2 text-base rounded-sm outline-none
          focus-within:border-farmlink_yellow focus-within:shadow-farmlinkInput duration-100' 
          onChange={(e) => setFarmerAge(e.target.value)} value={farmerAge} required />
          {
                        errFarmerAge && (
                            <p className='text-red-600 text-xs font-semibold tracking-wide
                            flex items-center gap-2 mt-0'>
                            <span className='italic font-titleFont font-extrabold text-base'>!</span>
                                {errFarmerAge}</p>
                        )
                      }
        </div>
        
        <div className='flex flex-col ml-[190px]'>
        <p className='text-sm font-bold'>Years of Farming</p>
          <input placeholder='Enter # years' type='number' className='w-[140px] py-1 
          border border-zinc-400 px-2 text-base rounded-sm outline-none
          focus-within:border-farmlink_yellow focus-within:shadow-farmlinkInput duration-100' 
          onChange={(e) => setFarmerYears(e.target.value)} value={farmerYears} required />
          </div>
        </div>
        {
                        errFarmerYears && (
                            <p className='text-red-600 text-xs font-semibold tracking-wide
                            flex items-center gap-2 ml-[300px] -mt-8'>
                            <span className='italic font-titleFont font-extrabold text-base'>!</span>
                                {errFarmerYears}</p>
                        )
                      }
          
          <p className='text-sm font-bold'>Upload Valid Identification</p>
          <span className='text-gray-500 text-sm text-justify'
          >* A card that verifies your identity, for the Department of Agriculture to assess.</span>

          
                   <select className='w-full py-1 border border-zinc-400 px-2 text-base 
                    rounded-sm outline-none focus-within:border-farmlink_yellow 
                    focus-within:shadow-farmlinkInput duration-100' required onChange={(e) => setSelectedID(e.target.value)}>
                    <option value="" disabled selected hidden>Select Valid ID</option>
                    <option>Philippine Postal ID</option>
                    <option>Social Security System (SSS) Card</option>
                    <option>Unified Multi-Purpose Identification (UMID) Card</option>
                    <option>Professional Regulatory Commission (PRC) ID</option>
                   
                  </select> 
                  {
                        errSelectedID && (
                            <p className='text-red-600 text-xs font-semibold tracking-wide
                            flex items-center gap-2 mt-0'>
                            <span className='italic font-titleFont font-extrabold text-base'>!</span>
                                {errSelectedID}</p>
                        )
                      }

                <input placeholder='' type='file' id='file' accept='image/*,.pdf'className='w-full 
                py-1 border border-zinc-400 px-2 text-base 
               rounded-sm outline-none focus-within:border-farmlink_yellow 
               focus-within:shadow-farmlinkInput duration-100'
               onChange={validImgHandler}required/>
               {
                        errValidImage && (
                            <p className='text-red-600 text-xs font-semibold tracking-wide
                            flex items-center gap-2 -mt-1.5'>
                            <span className='italic font-titleFont font-extrabold text-base'>!</span>
                                {errValidImage}</p>
                        )
                      }
                      <div className='flex flex-col items-center gap-3'>
               <img className='w-[200px] h-[200px]'src={validImage} />
               </div>
                      
               
          <p className='text-sm font-bold'>Location</p> 
            <input placeholder='Enter Address' type='text' 
                className='w-full py-1 border border-zinc-400 px-2 text-base 
               rounded-sm outline-none focus-within:border-farmlink_yellow 
               focus-within:shadow-farmlinkInput duration-100' value={sellerAddress}
               onChange={(e) => setSellerAddress(e.target.value)}required/>
                {
                        errSellerAddress && (
                            <p className='text-red-600 text-xs font-semibold tracking-wide
                            flex items-center gap-2 -mt-1.5'>
                            <span className='italic font-titleFont font-extrabold text-base'>!</span>
                                {errSellerAddress}</p>
                        )
                      }
                      
                      </div>
                {
                    successMsg && (
                        <div className='mt-0'>
                            <motion.p 
                            initial={{ y: 10, opacity: 0}}
                            animate={{ y:0, opacity: 1}}
                            transition={{duration: 0.5}}
                            className='text-base font-titleFont font-semibold text-green-500 border-[1px]
                            border-green-500 px-2 text-center'
                            >{successMsg}</motion.p>
                        </div>
                    )
                }
                    <button className="w-[200px] mdl:w-[400px] mdl:h-[40px] bg-[#ffa721] rounded-md 
                      py-2 font-semibold cursor-pointer hover:bg-[#d98d1c] ml-[25px]" 
                      type='submit' onClick={handleFarmerRegistration} >
                          Register as a Seller
                      </button>
                      </div>

                      </div>
                      </form>
                      </div>
                      </div>
                      
                      ):(
                        <div className='w-full h-[100px] flex items-center justify-center'>
                        <p className='font-bold'>Create an Account before accessing this page</p>
                        </div>
                      )}
                      </div>      
  )
}

export default RegisterFarmer