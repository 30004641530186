import React from 'react'
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Link } from 'react-router-dom';


const SideNavContent = ({title,one,two,three,four,five,six,seven,eight,nine,ten,eleven,twelve}) => {
  
  return (
    <div className="py-3 border-b-[1px] border-b-gray-300 z-50">
      <h3 className="text-lg font-titleFont font-semibold mb-1 px-6">
      {title}
      </h3>
      <ul className='text-sm'>
      <Link to="/crops">
        <li className="flex items-center justify-between hover:bg-zinc-200 px-6 py-2 cursor-pointer">
       {one}
          <span>
            <KeyboardArrowRightIcon />
          </span>
        </li>
        </Link>
        <Link to="/vegetables">
        <li className="flex items-center justify-between hover:bg-zinc-200 px-6 py-2 cursor-pointer">
       {two}
          <span>
            <KeyboardArrowRightIcon />
          </span>
        </li>
        </Link>
        {/* <Link to="/not-found">
        <li className="flex items-center justify-between hover:bg-zinc-200 px-6 py-2 cursor-pointer">
     {three}
          <span>
            <KeyboardArrowRightIcon />
          </span>
        </li>
        </Link>
        <Link to="/not-found">
        <li className="flex items-center justify-between hover:bg-zinc-200 px-6 py-2 cursor-pointer">
       {four}
          <span>
            <KeyboardArrowRightIcon />
          </span>
        </li>
        <li className="flex items-center justify-between hover:bg-zinc-200 px-6 py-2 cursor-pointer">
       {five}
          <span>
            <KeyboardArrowRightIcon />
          </span>
        </li>
        <li className="flex items-center justify-between hover:bg-zinc-200 px-6 py-2 cursor-pointer">
       {six}
          <span>
            <KeyboardArrowRightIcon />
          </span>
        </li>
        <li className="flex items-center justify-between hover:bg-zinc-200 px-6 py-2 cursor-pointer">
       {seven}
          <span>
            <KeyboardArrowRightIcon />
          </span>
        </li>
        <li className="flex items-center justify-between hover:bg-zinc-200 px-6 py-2 cursor-pointer">
       {eight}
          <span>
            <KeyboardArrowRightIcon />
          </span>
        </li>
        <li className="flex items-center justify-between hover:bg-zinc-200 px-6 py-2 cursor-pointer">
       {nine}
          <span>
            <KeyboardArrowRightIcon />
          </span>
        </li>
        <li className="flex items-center justify-between hover:bg-zinc-200 px-6 py-2 cursor-pointer">
       {ten}
          <span>
            <KeyboardArrowRightIcon />
          </span>
        </li>
        </Link>
        <li className="flex items-center justify-between hover:bg-zinc-200 px-6 py-2 cursor-pointer">
       {eleven}
          <span>
            <KeyboardArrowRightIcon />
          </span>
        </li>
         */}
        <Link to="/">
        <li className="flex items-center justify-between hover:bg-zinc-200 px-6 py-2 cursor-pointer">
       {twelve}
          <span>
            <KeyboardArrowRightIcon />
          </span>
        </li>
        </Link>
      </ul>
    </div>
  );
}

export default SideNavContent