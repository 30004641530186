import React from 'react'
import { ja, jus, michael, nel, pao, sean } from '../assets'

const AboutUs = () => {
  return (
    <div
    className='flex flex-row justify-center items-center text-xl font-bold pt-6 pb-6'>
      <div className='w-full h-full mx-auto flex flex-col items-center bg-white'>
        <br/>
        <h2> About Us</h2>
        <br />
        <div className='mdl:w-[750px] w-[300px]'>
        <p align="justify">In the ever-evolving landscape of technology, AniDelivery 
          stands as a testament to innovation, creativity, and a shared passion for 
          enhancing user experiences. Behind the interface and powerful functionalities 
          lie the dedicated minds of our exceptional development team, a group of visionaries 
          committed to turning ideas into reality.</p>
          </div>
          <br />
        <h2>Meet the Developers:</h2>
        <br />
        <div className='flex flex-wrap mdl:ml-0 ml-[85px] gap-10'>
        <div className='text-center'>
          <h2>Group Coordinator</h2>
            <img className='w-[200px] h-[200px]' src={michael}></img>
          <h2>Michael C. Caballa</h2>
          <br />

          <a className='w-[50px] text-base'>2020101843@dhvsu.edu.ph</a>
          </div>
          <div className='align-center text-center items-center'>
          <h2>Mobile Developer</h2>
            <img className='w-[200px] h-[200px]' src={nel}></img>
          <h2>Arnel C. David III</h2>
          <br />

          <a className='w-[50px] text-base'>2020106469@dhvsu.edu.ph</a> 
          </div>
          <div className='align-center text-center'>
          <h2>Manuscript</h2>
            <img className='w-[200px] h-[200px]' src={sean}></img>
          <h2 className='w-[200px] text-center'>Sean Raphael L. Estillore</h2>
          <a className='w-[50px] text-base'>20205886@dhvsu.edu.ph</a>
          </div>
          <div className='align-center text-center'>
          <h2>Website Developer</h2>
            <img className='w-[200px] h-[200px]' src={jus}></img>
          <h2 className='w-[200px] text-center'>Justine Charles E. Lacson</h2>
          
          <a className='w-[50px] text-base'>2020102465@dhvsu.edu.ph</a>
          </div>
          <div className='align-center text-center'>
          <h2>Manuscript</h2>
            <img className='w-[200px] h-[200px]' src={pao}></img>
          <h2>Paolo C. Lozendo</h2>
          <br />
          <a className='w-[50px] text-base'>2020106470@dhvsu.edu.ph</a>
          </div>
          <div className='align-center text-center'>
          <h2>Manuscript</h2>
            <img className='w-[200px] h-[200px]' src={ja}></img>
          <h2>Janea R. Manaloto</h2>
          <br />
          <a className='w-[50px] text-base'>2020102515@dhvsu.edu.ph</a>
          </div>
        </div>
        <br />
        
        </div>
        </div>
  )
}

export default AboutUs