import React, {useRef, useState, useEffect } from 'react'
import {logo} from "../../assets/index"
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SearchIcon from '@mui/icons-material/Search';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { useSelector } from 'react-redux';
import {Link} from "react-router-dom";


const HeaderBottom = () => {
    
    /* const products = useSelector((state)=>state.farmlinkReducer.products) */
    const [languageAll,setLanguageAll]= useState(false)
    const dogMenu = useRef(null)


    const closeOpenDogMenus = (e)=>{
        if(dogMenu.current && languageAll && !dogMenu.current.contains(e.target)){
          setLanguageAll(false)
        }
    }

    document.addEventListener('mouseup',closeOpenDogMenus)
  return (
    <div>
        <div className='w-full px-4 h-[60px] mdl:h-[100px] bg-white text-black flex items-center gap-4'>

        <Link to='/'>
        <div className='flex items-center border border-transparent cursor-pointer duration-100 mt-3'>
            <img className="-ml-[10px] w-[150px] mdl:w-[250px] h-10 mdl:h-[80px] xl:w-[300px] -mt-2.5 mb-0" src={logo} alt='logo' />
        </div>
        </Link>
    
    {/* <div className='h-10 rounded-md xs:w-[10px] flex flex-grow relative'>

        <input placeholder="Search AniDelivery" id="searchbox"className="h-full xs:w-[10px] text-base text-black flex-grow 
        outline-none bg-[#d3d6d5] border-black px-2 relative rounded-tl-md rounded-bl-md" type='text'/>
        <span className='w-12 h-full flex items-center justify-center bg-[#ffa721] hover:bg-[#ffbd5a] active:bg-[#eaac50] 
        duration-300 text-black cursor-pointer rounded-tr-md rounded-br-md'> 
            <SearchIcon />
        </span>
    </div> */}

{/* DROPDOWN LANGUAGE */}
{/* <div className='h-9 left-2 top-0 hidden lgl:flex relative' ref={dogMenu}>
        <button onClick={() => setLanguageAll(!languageAll)} id="english" className='w-40 h-full bg-gray-200 hover:bg-gray-300 border-2 cursor-pointer duration-300 text-m
     text-black font-titleFont flex items-center justify-center rounded-tl-md rounded-bl-md rounded-tr-md rounded-br-md'>ENGLISH<span></span><ArrowDropDownIcon />
     </button>
     {languageAll && (
            <div>
                <ul className="absolute w-40 h-30 top-10 left-0 bg-white border-[1px] border-farmlink_green text-black p-2 flex-col gap-1 z-50 rounded-tl-md rounded-bl-md rounded-tr-md rounded-br-md">
                <li className='text-m tracking-wide font-titleFont border-b-[1px] 
                            border-b-transparent hover:border-b-farmlink_green cursor-pointer duration-200'>ENGLISH</li>
                <li className='text-m tracking-wide font-titleFont border-b-[1px] 
                            border-b-transparent hover:border-b-farmlink_green cursor-pointer duration-200'>FILIPINO</li>
                </ul>
                </div>
                
        )}
    </div> */}
    <Link>
    {/* <div id="notifications" className='flex items-start top-0 justify-center 
    relative hover:cursor-pointer text-farmlink_yellow'>
        <NotificationsActiveIcon  fontSize="large"/>
    </div> */}
    </Link>
    {/* <Link to="/cart">
    <div id="cart" className='flex items-start top-0 justify-center relative hover:cursor-pointer'>
        <PointOfSaleIcon  fontSize="large"/>
        <p className='text-sm font-bold mt-3 text-whiteText'>
            Checkout <span className='absolute text-xs -top-1 left-5 font-semibold p-1 h-4 bg-[#ffa721] 
            text-black rounded-full flex justify-center items-center'>{products && products.length > 0 ? products && products.length:0}</span></p>
    </div>
    </Link> */}
 
</div>
</div>
  )}
export default HeaderBottom