import React from 'react'
import { dummyqr } from '../../assets';

const FooterMiddleList = ({title,listItem}) => {
   
  return (
    <div className='w-full'>
      <h3 className="font-titleFont text-white text-base font-semibold mb-3">
        {title}
      </h3>
      <ul className="flex flex-col gap-2 font-bodyFont">
        {listItem.map((item) =>
          item.listData.map((data,i) => 
          <div key={i} className="footerLink">{data}
          {/* <img src={dummyqr}></img> */}
          </div>
          )
        )}
        
      </ul>
    </div>
  );
}

export default FooterMiddleList