import React from 'react';
import { useEffect, useState } from 'react';
import { Products } from './Home/Products';
import { db } from '../firebase.config';
import { getAuth } from 'firebase/auth';

const VegetablesProduct = () => {

  const [vegetablesProducts, setVegetablesProducts] = useState([]);
  const auth = getAuth();


  const [vegetablesProductsGuest, setVegetablesProductsGuest] = useState([]);
  const [approved, setApproved] = useState([]);

  const getVegetables = async () =>{
    if(auth.currentUser){
    const vegetablesProducts = await db.collection('Products').where
    ("seller.userId" ,"!=", auth.currentUser.uid).where("productType" ,'==', "Vegetables").where("status" ,"==", "Approved").get();
    const vegetablesArray = [];
    for (var snap of vegetablesProducts.docs){
      var data = snap.data();
      data.uid = snap.id;
      vegetablesArray.push({
        ...data
      })
      if(vegetablesArray.length === vegetablesProducts.docs.length){
        setVegetablesProducts(vegetablesArray);
      }
    }
}}

  useEffect(()=>{
    getVegetables();
  },[auth.currentUser]) 

  const getVegetablesProductsGuest = async () =>{
    const vegetablesProducts = await db.collection('Products').where("productType" ,'==', "Vegetables").where("status" ,"==", "Approved").get();
    const vegetablesArray = [];
    for (var snap of vegetablesProducts.docs){
      var data = snap.data();
      data.uid = snap.id;
      vegetablesArray.push({
        ...data
      })
      if(vegetablesArray.length === vegetablesProducts.docs.length){
        setVegetablesProductsGuest(vegetablesArray);
      }
    }
  }
  
  useEffect(()=>{
    getVegetablesProductsGuest();
  },[auth.currentUser])


  /*  console.log(products) */


  return (
    <div className='w-full bg-gray-100'>
    <h1 className='w-full text-[30px] items-center font-titleFont mx-auto gap-1 text-center mt-4'>VEGETABLES</h1>
    <div className='-mt-4 flex flex-wrap mdl:ml-[200px]'>
          {/* <button className=''><span><RefreshIcon /></span></button> */}
        <br></br>
        {vegetablesProducts.length > 0 && (
          <div className='container-fluid'>
            <h1 className='text-center'></h1>
            <div id='productsform' className='mdl:h-full h-[950px] flex flex-wrap mdl:mb-10 -mb-[300px]'>
              <Products products={vegetablesProducts}/>
            </div>

          </div>
        )}
        {auth.currentUser == null && (
          <div className='container-fluid'>
          <h1 className='text-center'></h1>
          <div id='productsform' className='mdl:h-full h-[950px] flex flex-wrap mdl:mb-10 -mb-[300px]'>
            <Products products={vegetablesProductsGuest}/>
          </div>

        </div>
        )}
      </div>
    </div>
  )
}

export default VegetablesProduct