import React from 'react';
import { useEffect, useState } from 'react';
import { Products } from './Home/Products';
import { db } from '../firebase.config';
import { getAuth } from 'firebase/auth';

const CropsProduct = () => {

  const auth = getAuth();

  const [cropsProducts, setCropsProducts] = useState([]);
  const [cropsProductsGuest, setCropsProductsGuest] = useState([]);

  const getCrops = async () =>{
    if(auth.currentUser){
    const cropsProducts = await db.collection('Products').where
    (("seller.userId") ,"!=", auth.currentUser.uid).where("productType" ,'==', "Crops").where("status" ,"==", "Approved").get();
    const cropsArray = [];
    for (var snap of cropsProducts.docs){
      var data = snap.data();
      data.uid = snap.id;
      cropsArray.push({
        ...data
      })
      if(cropsArray.length === cropsProducts.docs.length){
        setCropsProducts(cropsArray);
      }
    }
}}

  useEffect(()=>{
    getCrops();
  },[auth.currentUser]) 

  const getCropsProductsGuest = async () =>{
    const cropsProducts = await db.collection('Products').where("productType" ,'==', "Crops").where("status" ,"==", "Approved").get();
    const cropsArray = [];
    for (var snap of cropsProducts.docs){
      var data = snap.data();
      data.uid = snap.id;
      cropsArray.push({
        ...data
      })
      if(cropsArray.length === cropsProducts.docs.length){
        setCropsProductsGuest(cropsArray);
      }
    }
  }
  
  useEffect(()=>{
    getCropsProductsGuest();
  },[auth.currentUser])
  /*  console.log(products) */

  return (
    <div className='w-full bg-gray-100'>
    <h1 className='w-full text-[30px] items-center font-titleFont mx-auto gap-1 text-center mt-4'>CROPS</h1>
    <div className='-mt-4 flex flex-row mdl:ml-[200px] items-start'>
          {/* <button className=''><span><RefreshIcon /></span></button> */}
        <br></br>
        {cropsProducts.length > 0 && (
          <div className='container-fluid'>
            <h1 className='text-center'></h1>
            <div id='productsform' className='mdl:h-full h-[950px] flex flex-wrap mdl:mb-10 -mb-[300px]'>
              <Products products={cropsProducts}/>
            </div>

          </div>
        )}
        {auth.currentUser == null && (
          <div className='container-fluid'>
            <h1 className='text-center'></h1>
            <div id='productsform' className='mdl:h-full h-[950px] flex flex-wrap mdl:mb-10 -mb-[300px]'>
              <Products products={cropsProductsGuest}/>
            </div>

          </div>
        )}
      </div>
    </div>
  )
}

export default CropsProduct