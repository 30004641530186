import React, { useState } from 'react'
import {logo}  from "../assets/index";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {Link, useNavigate} from "react-router-dom";
import { getAuth, createUserWithEmailAndPassword, updateProfile ,sendEmailVerification} from "firebase/auth";
import { ProgressBar } from 'react-loader-spinner';
import { motion } from 'framer-motion';
import {auth, db} from '../firebase.config';

export const Registration = (props) => {
    const navigate =useNavigate();

    const [userName, setUserName]=useState("");
    const [email, setEmail]=useState("");
    const [password, setPassword]=useState("");
    const [cPassword, setCPassword]=useState("");

    //error msg
    const [errUserName, setErrUserName] = useState("");
    const [errEmail, setErrEmail] = useState("");
    const [errPassword, setErrPassword] = useState("");
    const [errCPassword, setErrCPassword] = useState("");
    const [firebaseErr, setFirebaseErr] = useState("");

    //loading 

    const [loading, setLoading] = useState(false);
    const [successMsg, setSuccessMsg] = useState("");

    //emailValidation

    //email validation
    const emailValidation = (email)=>{
        return String(email).toLowerCase()
        .match(/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/);
    }

const Registration = (e) => {
    e.preventDefault();
    if(!userName){
        setErrUserName("Enter your name")
    }
    if(!email){
        setErrEmail("Enter your email");
        setFirebaseErr("")
    }else{
        if(!emailValidation(email)){
            setErrEmail("Enter a valid email")
        }
    }
    if(!password){
        setErrPassword("Enter your password");
    }else{
        if(password.length < 8){
            setErrPassword("Password must contain at least 8 characters with atleast 1 uppercase letter and 1 symbol");
        }
    }
    if(!cPassword){
        setErrCPassword("Confirm your password");
    }else{
        if(cPassword !== password){
            setErrCPassword("Password must match");
        }
    }
    
    if(userName && email && emailValidation(email) && password && password.length >=6 && cPassword
        && cPassword === password){
            setLoading(true)
    auth.createUserWithEmailAndPassword(email,password).then((cred)=>{
        
        db.collection('user').doc(cred.user.uid).set({
            fullName: userName,
            email: email,
            userId:cred.user.uid,
            imagePath:"",
            userType: "user",
            contactNumber: ""
        })

        sendEmailVerification(cred.user)
        .then(()=>{
            setUserName('');
            setEmail('');
            setPassword('');
            setCPassword('');
            setErrUserName('');
            setErrEmail('');
            setErrPassword('');
            setErrCPassword('');
            setSuccessMsg("Account Created Successfully!");
            setTimeout(() => {
                navigate("/signin")
                alert("Email verification link sent! Please verify your account by clicking on the link sent to your email!")
            }, 500);

            
        }).catch(err=>setFirebaseErr(err.message));
    }).catch(err=>setFirebaseErr(err.message));
}
}
    
  return (
  <div className='w-full -mt-10'>
    <div className='w-full bg-gray-100 pb-10'>
    <form className='w-[350px] mx-auto flex flex-col items-center' onSubmit={Registration}>
    <Link to="/">
    <img  className='w-[250px] h-[100px] mt-[60px] mb-5' src={logo} alt='logo' />
    </Link>
    <div className='w-full border border-zinc-200 p-6'>
        <h2 className='font-titleFont text-3xl font-medium mb-4'>Create Account</h2>
        <div className='flex flex-col gap-3'>
            <div className='flex flex-col gap-2'>
                <p className='text-sm font-medium'>Your name</p>
                <input onChange={(e) => setUserName(e.target.value)}value={userName} 
                placeholder='Name here'
                 type='text' className='w-full py-1 border border-zinc-400 px-2 text-base 
                      rounded-sm outline-none focus-within:border-farmlink_yellow 
                      focus-within:shadow-farmlinkInput duration-100' />
                      {
                        errUserName && (
                            <p className='text-red-600 text-xs font-semibold tracking-wide
                            flex items-center gap-2 -mt-1.5'>
                            <span className='italic font-titleFont font-extrabold text-base'>!</span>
                                {errUserName}</p>
                        )
                      }
            </div>
            <div className='flex flex-col gap-2'>
            <p className='text-sm font-medium'>Email address</p>
                <input onChange={(e) => setEmail(e.target.value)} value={email} 
                placeholder='Email address here'
                type='email' toLowerCase className='w-full py-1 border border-zinc-400 px-2 text-base 
                      rounded-sm outline-none focus-within:border-farmlink_yellow 
                      focus-within:shadow-farmlinkInput duration-100' />
                      {
                        errEmail && (
                            <p className='text-red-600 text-xs font-semibold tracking-wide
                            flex items-center gap-2 -mt-1.5'>
                            <span className='italic font-titleFont font-extrabold text-base'>!</span>
                                {errEmail}</p>
                        )
                      }
                      {
                        firebaseErr && (
                            <p className='text-red-600 text-xs font-semibold tracking-wide
                            flex items-center gap-2 -mt-1.5'>
                            <span className='italic font-titleFont font-extrabold text-base'>!</span>
                                {firebaseErr}</p>
                        )
                      }
            </div>
            <div className='flex flex-col gap-2'>
            <p className='text-sm font-medium'>Password</p>
                      <input onChange={(e) => setPassword(e.target.value)}value={password} 
                      placeholder='Password here' className='w-full 
                       py-1 border border-zinc-400 px-2 text-sm 
                      rounded-sm outline-none focus-within:border-farmlink_yellow 
                      focus-within:shadow-farmlinkInput duration-100'type='password' />
                      
                      {
                        errPassword && (
                            <p className='text-red-600 text-xs font-semibold tracking-wide
                            flex items-center gap-2 -mt-1.5'>
                            <span className='italic font-titleFont font-extrabold text-base'>!</span>
                                {errPassword}</p>
                        )
                      }
                      <a className='text-gray-400 text-sm'>At least 8 characters, 1 uppercase letter, and 1 symbol</a>
            </div>
            <div className='flex flex-col gap-2'>
            <p className='text-sm font-medium'>Re-enter password</p>
                      <input onChange={(e) => setCPassword(e.target.value)}value={cPassword}
                      placeholder='Confirm password here'
                      className='w-full  py-1 border border-zinc-400 px-2 text-base 
                      rounded-sm outline-none focus-within:border-farmlink_yellow 
                      focus-within:shadow-farmlinkInput duration-100'type='password' />
                      {
                        errCPassword && (
                            <p className='text-red-600 text-xs font-semibold tracking-wide
                            flex items-center gap-2 -mt-1.5'>
                            <span className='italic font-titleFont font-extrabold text-base'>!</span>
                                {errCPassword}</p>
                        )
                      }
            </div>
            <button type='submit'
                    className='w-full font-titleFont font-medium text-base bg-gradient-to-tr
                from-farmlink_yellow to-yellow-200 border hover:from-yellow-300 hover:to-yellow-200 
                border-yellow-500 hover:border-yellow-700 active:bg-gradient-to-bl
                active:from-yellow-400 active:to-yellow-500 duration-200 py-1.5 mt-4'>Continue
                </button>
                {
                    loading && (
                        <div className='flex justify-center'>
                            <ProgressBar
                            height="80"
                            width="80"
                            ariaLabel="progress-bar-loading"
                            wrapperStyle={{}}
                            wrapperClass="progress-bar-wrapper"
                            borderColor = '#ffa721'
                            barColor = '#017a3f'
                            />
                        </div>
                    )
                }
                {
                    successMsg && (
                        <div>
                            <motion.p 
                            initial={{ y: 10, opacity: 0}}
                            animate={{ y:0, opacity: 1}}
                            transition={{duration: 0.5}}
                            className='text-base font-titleFont font-semibold text-green-500 border-[1px]
                            border-green-500 px-2 text-center'
                            >{successMsg}</motion.p>
                        </div>
                    )
                }
                
        </div>
        <p className='text-xs text-black leading-4 mt-4'>By continuing, you agree to FarmLink's 
                  <span className='text-blue-600 hover:text-orange-700 
                  hover:underline underline-offset-1 cursor-pointer'> Conditions of Use </span> and <span 
                  className='text-blue-600 hover:text-orange-700 
                  hover:underline underline-offset-1 cursor-pointer'>Privacy Notice.</span>
                  </p>
                  <div>
                    <p className='text-xs text-gray-600 mt-4 cursor-auto'>
                        Already have an account? 
                        <Link to='/signin'>
                        <span className='text-blue-600 hover:text-orange-600 
                        hover:underline underline-offset-1 cursor-pointer duration-100'
                        > {" "}Sign in<span><ArrowRightIcon /></span></span>         
                        </Link>
                        </p>
                  </div>
    </div>
    </form>
    </div>
    <div className='w-full bg-gradient-to-t from-white via-white to-zinc-200 flex flex-col gap-4 justify-center
        items-center py-10'>
        <div className='flex items-center gap-6'>
          <p className='text-xs text-blue-600 hover:text-orange-600 hover:underline
          underline-offset-1 cursor-pointer duration-100'>Conditions of Use</p>
          <p className='text-xs text-blue-600 hover:text-orange-600 hover:underline
          underline-offset-1 cursor-pointer duration-100'>Privacy Notice</p>
          <p className='text-xs text-blue-600 hover:text-orange-600 hover:underline
          underline-offset-1 cursor-pointer duration-100'>Help</p>
        </div>
        <div>
          <p className='text-xs text-gray-600'>© 2023, FarmLink.com, Inc. or its affiliates</p>
        </div>
        </div>
    </div>

  )
}

