import React from 'react'
import {Link} from 'react-router-dom'
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { db } from '../../firebase.config';

const FooterTop = () => {
  const userInfo = useSelector((state) => state.farmlinkReducer.userInfo)
  const auth = getAuth();

  const [userExists, setUserExists] = useState(false);


    useEffect(() => {
      if(auth.currentUser){
      
      const subscriber = db.collection('user').doc(auth.currentUser.uid)
        .onSnapshot((doc) => {
          setUserExists(!doc.empty)
        });
        // return cleanup function
      return () => subscriber();
      }
  
      
    },[]); // empty dependencies array => useEffect only called once

  return (
    <div className='w-full'>
    {/* {<div>
    {
    userExists ? 
    (
      <div className='w-full h-10 py-10'></div>

    ) : (
      <div className="w-full bg-white py-[0px]">
      <div className="w-full border-t-[1px] border-b-[1px] py-8">
        <div className="w-64 mx-auto text-center flex flex-col gap-1">
          <p className="text-sm">See Personalised recommendations</p>
          <Link to="/signin">
          
          <button className="w-full bg-[#ffa721] rounded-md py-1 font-semibold cursor-pointer hover:bg-[#d98d1c]">
            Sign In
          </button>
          </Link>
          <p className="text-xs mt-1">
            New Customer?{" "}
            <Link to="/registration">
            <span className="text-blue-600 ml-1 cursor-pointer hover:underline underline-offset-1">
              Start here.
            </span>
            </Link>
          </p>
        </div>
      </div>
    </div>
    )}
    </div>} */}
    </div>
  );
}

export default FooterTop