import React, { useState, useEffect } from 'react';
import {storage, db} from '../firebase.config';
import { useNavigate } from 'react-router-dom';
import { ProgressBar } from "react-loader-spinner";
import { getAuth } from 'firebase/auth';
import { v4 as uuidv4 } from 'uuid';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import { collection, addDoc } from 'firebase/firestore';


   export const AddProducts = (e) => {
    //loading
    const [loading, setLoading] = useState(false);
    const [successMsg, setSuccessMsg] = useState("");
   /*  const [successMsg, setSuccessMsg] = useState(""); */
    //products information
    const [productPrice, setProductPrice] = useState(0);
    const [productImgs, setProductImgs] = useState([]);
    const [productQuantity, setProductQuantity] = useState(0);
    const [productImages, setProductImages] = useState([]);
    const [urls, setUrls] = useState([])
    const [error, setError] = useState('');

    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedProduct, setSelectedProduct] = useState('');
    const [selectedVariety, setSelectedVariety] = useState('');

    //product image
    const types = ['image/png','image/jpeg','image/jpg'];
    /* const [file, setFile] = useState(''); */
       
    const [selected, setSelected] = useState(""); 
    const [selectedProductType, setSelectedProductType] = useState(""); 
    
    const userInfo = useSelector((state) => state.farmlinkReducer.userInfo)
    const auth = getAuth();
    const navigate =useNavigate();

    const handleChange = (e) =>{
      for(let i = 0; i < e.target.files.length; i++){
        let selectedFile = e.target.files[i];
        if(selectedFile && types.includes(selectedFile.type)){
          selectedFile["id"] = Math.random();
            setProductImgs((prevState) => [...prevState, selectedFile]);
            //setProductImages(URL.createObjectURL(e.target.files[i]));
            setError('');
        }
        else{
            setProductImgs([]);
            //setProductImages([]);
            setError('Please select a valid image type (png, jpeg, or jpg)');
        }
      }
        
    }

  const [userExists, setUserExists] = useState(false);
  const [posts, setPosts] = useState([]);

    useEffect(() => {
      if(auth.currentUser){
      const getPostsFromFirebase = [];
      const subscriber = db.collection('user').doc(auth.currentUser.uid)
        .onSnapshot((doc) => {
          setUserExists(!doc.empty)
            getPostsFromFirebase.push({
              ...doc.data(), //spread operator
              key: doc.id, // `id` given  by Firebase
            });
          
          setPosts(getPostsFromFirebase);
        });
        // return cleanup function
      return () => subscriber();
      }
  
      
    },[auth.currentUser]); // empty dependencies array => useEffect only called once
    const myUuid = uuidv4();
    const myUuid2 = uuidv4();
    const myUuid3 = uuidv4();

    const handleUpload = (e) =>{
    
      const promises = [];
      e.preventDefault();
      productImgs.map((image) => {
        const uploadTask = storage.ref(`products/images/${image.name}`).put(image);
        promises.push(uploadTask)
      uploadTask.on('state_changed', snapshot => {
          const progress = (snapshot.bytesTransferred/snapshot.totalBytes) * 100;
          console.log(progress);
      }, err=> {
          setError(err.message)
      },async ()=>{
        await storage
        .ref("products/images/")
        .child(image.name)
        .getDownloadURL()
        .then((url)=>{
          setUrls((prevState)=>[...prevState, url]);
          /* setProductImgs([]);
          setProductImages([]);
          setTimeout(() => {
            setUrls([]);
          }, 1000); */
        });
      }
      );
    });
    Promise.all(promises)
    .then(()=> alert("All images uploaded!"))
    .catch((err)=>console.log(err));
  };

 /*    console.log("image: ", productImgs)
    console.log("urls", urls) */

    const addproduct = (e) => {

    /*     const myUuid = uuidv4(); */
        e.preventDefault();
       
  {posts.map((post) => {
          const repRef = db.collection("user").doc(auth.currentUser.uid);

    repRef.collection("sellerInformation").doc("sellerInfoDocument").get().then((docs) => {
      const shopLocation = docs.data().shopLocation;
         
            /* storage.ref('products/images/').child(productImgs.name).getDownloadURL().then(url=>{ */
              //const docRef = db.collection('Products').setDoc({
                addDoc(collection(db, "Products"),{
                    productType: selectedCategory,
                    name: selectedProduct,
                    productVariety: selectedVariety,
                    quantity: Number(productQuantity),
                    price: Number(productPrice),
                    id: myUuid3,
                    productLocation:shopLocation,
                    status:"Pending",

                    seller:{
                      userId: auth.currentUser.uid,
                      email: post.email,
                      fullName: post.fullName,
                      imagePath: post.imagePath,
                      contactNumber: post.contactNumber,
                      userType: post.userType
                      //contactNumber: userInfo.contactNumber
                    },
                    images:[
                      `${urls[0]}`,`${urls[1]}`

                    ]

                }).then((docRef)=>{
                   /* updateDoc(washingtonRef, {
                    regions: arrayUnion("greater_virginia")
                }); */
                  //const docId = docRef.id;
                  //console.log('Document ID:', docId);
                    setSelectedProduct("");
                    setProductPrice("");
                    setSelectedVariety("");
                    setProductQuantity("");
                    setSelectedCategory("")
                    setSuccessMsg("Product Successfully Posted!");
                    setProductImgs(null);
                    setError('');
                    document.getElementById('files').value = '';
                }).catch(err=>setError(err.message));
                setLoading(true)
            setTimeout(() => {
                navigate("/shop")
              }, 1000);
            }
          
            
        

 )})}} 
//}
    
    
 
  const changeSelectOptionHandler = (event) => { 
    setSelected(event.target.value); 
    setSelectedCategory(event.target.value)
  }; 

  const changeSelectOptionHandler2 = (event) => { 
    setSelectedProductType(event.target.value); 
    setSelectedProduct(event.target.value)
  }; 
  
  
  /** Different arrays for different dropdowns */
  const Vegetables = [ 
    "Eggplant", 
    "Pepper", 
    "Sitaw",
    "Ampalaya", "Tomato"
  ]; 
  const Crops = ["Corn", "Potato",
   "Cassava", "Rice"]; 
  const Fruits = ["Mango (Mangga)", "Tomato"]; 

  //vegetables
  const Eggplant = ["Fortuner F1 Eggplant", "Banate King F1 Eggplant"];
  const Pepper = ["Siling Labuyo", "Siling Haba","Siling Tingala","Siling Makopa"];
  const Stringbeans = ["Green Pod Sitaw"];
  const Bittergourd = ["Green Ampalaya"];

  //crops
  const Corn = ["Sweet Corn", "White Lagkitan Corn"];
  const SweetPotato = ["Russet Potato", "Sweet Potato"];
  const Cassava = ["Cassava"];
  const Rice = ["NFA (Well Milled Rice)", "Special Rice","Premium Rice","Well Milled Rice",
"Regular Milled Rice"]; 
  
  //fruits
  const Mango = ["Carabao Mango", "Indian Mango"];
  const Tomato = ["Roma Tomato", "Rio Grande Tomato", "Cherry Tomato"];


  
  /** Type variable to store different array for different dropdown */
  let type = null; 
  
  /** This will be used to create set of options that user will see */
  let options = null; 

  let type2 = null;
  let options2 = null;
  
  /** Setting Type variable according to dropdown */
  if (selected === "Vegetables") { 
    type = Vegetables; 
  } else if (selected === "Crops") { 
    type = Crops; 
  }/*  else if (selected === "Fruits") { 
    type = Fruits; 
  }  */

  if (selectedProductType === "Eggplant") { 
    type2 = Eggplant; 
  } else if (selectedProductType === "Pepper") { 
    type2 = Pepper; 
  } else if (selectedProductType === "Sitaw") { 
    type2 = Stringbeans; 
  } else if (selectedProductType === "Ampalaya") { 
    type2 = Bittergourd; 
  } else if (selectedProductType === "Corn") { 
    type2 = Corn; 
  } else if (selectedProductType === "Potato") { 
    type2 = SweetPotato; 
  } else if (selectedProductType === "Cassava") { 
    type2 = Cassava; 
  } else if (selectedProductType === "Rice") { 
    type2 = Rice; 
  }else if (selectedProductType === "Tomato") { 
    type2 = Tomato; 
  }/*  else if (selectedProductType === "Mango (Mangga)") { 
    type2 = Mango; 
  } else if (selectedProductType === "Tomato (Kamatis)") { 
    type2 = Tomato; 
  }  */


  
  
  /** If "Type" is null or undefined then options will be null, 
   * otherwise it will create a options iterable based on our array 
   */
  if (type) { 
    options = type.map((el) => <option key={el}>{el}</option>); 
  } 

  if (type2) { 
    options2 = type2.map((el2) => <option key={el2}>{el2}</option>); 
  } 

        
        return (
          <div>
            
            <div className='w-full'>
                <div className='mdl:w-full w-[345px] bg-white-100 pb-10 pt-5 items-center'>
                
                <form autoComplete="off" className='mdl:w-[500px] w-full mx-auto flex flex-col items-center'
                 onSubmit={addproduct}>
                <div className='w-full rounded-tr-md rounded-br-md rounded-tl-md rounded-bl-md p-6 bg-white'>
                <br/>
                <h2 className='font-titleFont text-2xl font-medium mb-4 -mt-4'>{" "}Post a Product</h2>
                <hr/>
                <div className='flex flex-col gap-3'>
                  <div className='flex flex-col gap-2'>
                <p className='text-sm font-bold'>Category</p>
                  <select className='w-full py-1 border border-zinc-400 px-2 text-base 
                    rounded-sm outline-none focus-within:border-farmlink_yellow 
                    focus-within:shadow-farmlinkInput duration-100' required /* onChange={(e) => 
                    setSelectedCategory(e.target.value)} */ onChange={changeSelectOptionHandler}>
                    <option value="" disabled selected hidden>Choose A Product Type</option>
                    {/* {categories.map((category) => (
                      <option key={category} value={category}>
                        {category}
                      </option>
                    ))} */}
                    <option>Vegetables</option> 
                    <option>Crops</option> 
                   {/*  <option>Fruits</option>  */}
                  </select>
        
                  <p className='text-sm font-bold'>Product Name</p>
                   <select className='w-full py-1 border border-zinc-400 px-2 text-base 
                    rounded-sm outline-none focus-within:border-farmlink_yellow 
                    focus-within:shadow-farmlinkInput duration-100' required /* onChange={(e) => 
                    setSelectedProduct(e.target.value)} */ onChange={changeSelectOptionHandler2}>
                  <option value="" disabled selected hidden>Choose a Product to Sell</option>
                   
              {
              options 
            } 
                  </select> 

                  <p className='text-sm font-bold'>Product Variety</p>
                   <select className='w-full py-1 border border-zinc-400 px-2 text-base 
                    rounded-sm outline-none focus-within:border-farmlink_yellow 
                    focus-within:shadow-farmlinkInput duration-100' required onChange={(e) => setSelectedVariety(e.target.value)}>
                    <option value="" disabled selected hidden>Choose Product Variety</option>
                    {/* {productVarietyName.map((productVariety) => (
                      <option key={productVariety} value={productVariety}>
                        {productVariety}
                      </option>
                    ))} */}
                    {options2}
                  </select> 
                  
                    <p className='text-sm font-bold'>Product Quantity (kg)</p>
                  <input type='text' className='w-full py-1 border border-zinc-400 px-2 text-base 
                    rounded-sm outline-none focus-within:border-farmlink_yellow 
                    focus-within:shadow-farmlinkInput duration-100' 
                    placeholder='Enter Item Qty. in Kilograms' required onChange={(e) => setProductQuantity(e.target.value)}></input>
        
                    <p className='text-sm font-bold'>Product Price per Kilogram</p>
                  <input type='text' className='w-full py-1 border border-zinc-400 px-2 text-base 
                    rounded-sm outline-none focus-within:border-farmlink_yellow 
                    focus-within:shadow-farmlinkInput duration-100' 
                    placeholder='Enter Price per Kilogram' required onChange={(e) => setProductPrice(e.target.value)}></input>
                    
                    
                    <h2 className='text-sm font-bold'>Add Product Image: (Choose 1-2 Images and Upload using the Upload button)</h2>
                    <div className='mdl:text-center items-center'>
                    <input type="file" id='files' required multiple onChange={handleChange} />
                    <button onClick={handleUpload} required className='w-[115px] h-[30px] bg-gray-100 hover:bg-gray-200 
                    hover:cursor-default rounded-sm border-[1px] border-black mb-5'>Upload</button>
                    <div className='flex mdl:flex-row flex-col items-center'>
                    {urls.map((url, i) =>(
                        <div key={i}>
                          <img className='w-[200px] h-[200px] mdl:ml-[17px]'src={url} />
                          <br />
                        </div>
                       
                    ))}
                    
                    </div>
                    </div>
                    
                    {error&&<span>{error}</span>}
                </div>
                </div>
                {
                    successMsg && (
                        <div className='mt-14'>
                            <motion.p 
                            initial={{ y: 10, opacity: 0}}
                            animate={{ y:0, opacity: 1}}
                            transition={{duration: 0.5}}
                            className='text-base font-titleFont font-semibold text-green-500 border-[1px]
                            border-green-500 px-2 text-center'
                            >{successMsg}</motion.p>
                        </div>
                    )
                }
                   { loading && (
                        <div className='flex justify-center'>
                            <ProgressBar
                            height="80"
                            width="80"
                            ariaLabel="progress-bar-loading"
                            wrapperStyle={{}}
                            wrapperClass="progress-bar-wrapper"
                            borderColor = '#ffa721'
                            barColor = '#017a3f'
                            />
                        </div>
                    )
                   }
                    <div className='items-center text-center'>
                        <button className="mdl:w-[400px] w-[200px] h-[40px] bg-[#ffa721] rounded-md 
                          py-2 mt-4 font-semibold cursor-pointer hover:bg-[#d98d1c]" 
                          type='submit' >
                              Post Product
                          </button>
                          
                          </div>
                    </div>
                    
                </form>
 {/*   )} */}
                </div>
                
                </div>
                {/* ):(
                  <div className='w-full h-[100px] flex items-center justify-center'>
                  <p className='font-bold'>Create an Account or Register as a Seller before accessing this page</p>
                  </div>
                )} */}
                </div>
          )
    }

