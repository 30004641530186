import React, {useEffect,useState} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { decrementQuantity, incrementQuantity, removeItem, resetCart } from '../redux/farmlinkSlice';
import {emptycart} from "../assets/index"
import {motion} from "framer-motion";
import { db } from '../firebase.config';
import { ProgressBar } from "react-loader-spinner";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { Link } from 'react-router-dom';

import { getAuth } from 'firebase/auth';

import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

const Cart = () => {

  const navigate = useNavigate();
  const auth = getAuth();
  const [paymentOption, setPaymentOption] = useState('');

  const [address, setAddress] = useState([])

  const [userExists, setUserExists] = useState(false);

    useEffect(() => {
      if(auth.currentUser){
      const subscriber = db.collection('user').doc(auth.currentUser.uid)
        .onSnapshot((doc) => {
          setUserExists(!doc.empty)
        });
        // return cleanup function
      return () => subscriber();
      }
  
      
    },[auth.currentUser]); // empty dependencies array => useEffect only called once

  const getUserAddress = async () =>{
    
    if(auth.currentUser){
    const address = await db.collection('user').doc(auth.currentUser.uid).collection('shippingAddress').where(("userId") ,'==', auth.currentUser.uid).get();
    const addressArray = [];
    for (var snap of address.docs){
      var data = snap.data();
      data.uid = snap.id;
      addressArray.push({
        ...data
        
      })

      if(addressArray.length === address.docs.length){
        setAddress(addressArray);
      }
    }
    
  }
}
  useEffect(()=>{
    getUserAddress();
  },[])

  const [loading, setLoading] = useState(false);
  const [error ,setError] = useState("")

  const dispatch = useDispatch()
  const products = useSelector((state)=>state.farmlinkReducer.products);

const currentDate = new Date();
const year = currentDate.getFullYear();
const month = currentDate.getMonth() + 1;
const day = currentDate.getDate();
let ampm = currentDate.getHours() >= 12 ? 'pm' : 'am';

let hours =`${(currentDate.getHours() % 12  || 12)}`;
if (hours < 10) { hours = "0" + hours; }
const hours0 = hours
const minutes = `${(currentDate.getMinutes())} ${ampm}`;
const formattedDate = `${year}-${month}-${day}`;
const formattedTime = `${hours0}:${minutes}`;

const [first, setFirst] =useState(true);
const [second, setSecond] =useState(true);


const checkoutConfirmation = (e) => {
  e.preventDefault();
          if(confirm("Are all the information correct?") == true) {
            checkoutOrder();
          } else {
            alert("Please add a shipping address in the profile menu!")
          }
       
   
      }

       var desiredMaxLength = 11
var randomNumber = '';
for (var i = 0; i < desiredMaxLength; i++) {
    randomNumber += Math.floor(Math.random() * 10);
}

const checkoutOrder = (e) => {

  const myUuid = uuidv4();

  if(address.length > 0){
{products.map((item)=>(
  address.map((address1)=>(

   db.collection('user').doc(auth.currentUser.uid).collection('orders').add({

          buyQuantity:  Number(item.quantity),
          totalPrice: Number(item.price * item.quantity),
          orderId: randomNumber,
          orderStatus: "Ordered",
          orderType: paymentOption,
          date: "",
          buyerId: auth.currentUser.uid,
          trackingNumber: "",
          courier: "",
          notified: "",
          date: formattedDate + ", " + formattedTime,

          address:{
            street: address1.street,
            barangay: address1.barangay,
            city: address1.city,
            postalCode: address1.postalCode,
            province: address1.province,
            addressType: address1.addressType,
            fullNameShipping: address1.fullNameShipping,
            phone: address1.phone
      },
      product:{
        id: item.productId,
        name: item.title,
        productType: item.category,
        productLocation: "",
        productVariety: item.variety,
        quantity: item.quantity,
        price: item.price,
        images:[
          item.image
        ],
        seller:{
          contactNumber:"",
          email: item.sellerEmail,
          fullName: item.sellerFullName,
          imagePath: "",
          userId: item.sellerId, 
          userType: "user",
        }
      }
    })
      .then(() => {
        checkOutSellerOrders()
        setLoading(true)
        {products.map((item)=>(
          setTimeout(() => {
            alert("Order placed!"),
              navigate("/")
              dispatch(resetCart(item.id)
              )
            }, 1000))
          )}
      })
      .catch((err)=>{
        alert("Please add your shipping address in the profile menu"+ err);
                })
        ))
      ))
    }}else{
      alert("Please add your shipping address in the profile menu!");
    }
   }

        const checkOutSellerOrders = () =>{

          const myUuid = uuidv4();

          {products.map((item)=>(
            address.map((address1)=>(
          
          db.collection('user').doc(item.sellerId).collection('sellerOrders').add({
          buyQuantity:  Number(item.quantity),
          totalPrice: Number(item.price * item.quantity),
          orderId: myUuid,
          orderStatus: "Ordered",
          deliveryType: "Seller Delivery",
          orderType: paymentOption,
          date: "",
          buyerId: auth.currentUser.uid,
          trackingNumber: "",
          courier: "",
          notified: "",
          date: formattedDate + ", " + formattedTime,

          address:{
            street: address1.street,
            barangay: address1.barangay,
            city: address1.city,
            postalCode: address1.postalCode,
            province: address1.province,
            addressType: address1.addressType,
            fullNameShipping: address1.fullNameShipping,
            phone: address1.phone
      },
      product:{
        id: item.productId,
        name: item.title,
        productType: item.category,
        productLocation: "",
        productVariety: item.variety,
        quantity: item.quantity,
        price: item.price,
        images:[
          item.image
        ],
        seller:{
          contactNumber:"",
          email: item.sellerEmail,
          fullName: item.sellerFullName,
          imagePath: "",
          userId: item.sellerId, 
          userType: "user",
        }
      }
    })
        ))))}}



const handleChange = (data) =>{

if(data == "Cash-on-Delivery")
{
  setFirst(!first)
  setPaymentOption(data)
}
else if(data == "Cashless Payment")
{
  setSecond(!second)
  setPaymentOption(data)
}
}


  return (
    <div>
    {userExists ? (
    <div className='w-full bg-gray-100 p-4'>
      {
        products.length == 1 ? (
          <div className='container h-auto w-[500px] mdl:ml-[700px] mt-10'>
        <div className='mdl:w-full w-[350px] h-full bg-white px-4 col-span-4'>
          <div className='font-titleFont flex items-center justify-between border-b-[1px]
          border-b-gray-400 px-3'>
              <h2 className='text-3xl font-semibold'>Direct Checkout</h2>
              <h4 className='text-sm font-normal' >Subtotal</h4>
          </div>
          <div>
            {
              products.map((item)=>(
                <div key={item.id} className='w-full border-b-[1px] border-b-gray-300 p-4 flex items-center
                gap-6'>
                  <div className='w-full flex items-center gap-6'>
                  <div className='w-2/5'>
                  <img className='h-44 object-contain'
                  src={item.image}
                  alt='ProductImg'
                  />
                  </div>
                  <div className='w-4/5'>
                    <h2 className='font-semibold text-lg -mt-[40px] absolute'>{item.title}</h2>
                    <h2 className='text-xs capitalize  absolute text-black -mt-4'>
            {item.variety}</h2>
                    <p className='text-base'>Unit Price <span className='font-semibold'>₱{item.price}</span></p>
                    <div className='bg-[#F0F2F2] flex justify-center items-center gap-1 w-[150px]
                    py-1 text-center drop-shadow-lg rounded-md'>
                      <p>Qty in kg:</p>
                      <p onClick={()=>dispatch(decrementQuantity(item.id))} className='cursor-pointer bg-gray-200 px-1 rounded-md
                      hover:bg-gray-400 duration-300'>-</p>

                      <a id="score">{item.quantity}</a>

                      <button onClick={()=>dispatch(incrementQuantity(item.id))} id='#btn' className='cursor-pointer bg-gray-200 px-1 rounded-md
                      hover:bg-gray-400 duration-300'>+</button>
                    </div>
                    <button onClick={()=>dispatch(removeItem(item.id),setTimeout(() => {
                  navigate("/");
                  }, 100))} className='bg-red-500 w-36 py-1 rounded-lg text-white mt-2
                    hover:bg-red-700 active:bg-red-900 duration-300'>Cancel</button>
                  </div>
                  <div>
                    <p className='text-lg font-titleFont font-semibold'>₱{item.price * item.quantity}</p>
                  </div>
                    </div>
                </div>
              ))}

              <form onSubmit={checkoutConfirmation}>
                
              {address.length == 1 ? ( 
                address.map((address1)=>
                <div className='py-2 flex flex-col items-center text-center' key={address1.key}>
                    <div className='border-[1px]'>
                      <h2 className='font-bold'><span><LocationOnIcon /></span>{" "}Delivery Address</h2>
                      <h2 className='font-bold'>{address1.fullNameShipping}</h2>
                      <h2 className='font-bold'>{address1.phone}</h2>
                      <h2>{address1.street} {address1.barangay}, {address1.city} {address1.postalCode}, {address1.province}</h2>
                      
                    </div>
                    </div>
              )) : (
                
                
                    <div className='py-2 flex flex-col items-center text-center'>
                    <div className='border-[1px]'>
                  <h2 className='font-bold'><span><LocationOnIcon /></span>{" "}Delivery Address</h2>
                  <div className='flex flex-row items-center text-center ml-20 mdl:ml-[160px] gap-10'>
                    <h1>No Address Added!</h1>
                  <Link to='/useraddress'>
                  <button id='plusaddress' className='hover:bg-gray-200 h-[30px] w-[30px]'><span className='text-xl font-bold'>+</span></button>
                  </Link>
                  </div>
                  
                  
                  <h2 className=''>(Click the + sign to add your shipping address in the Add a Shipping Address page)</h2>
                  
                  </div>
                  </div>
              )}
                
                 <div className='flex flex-col px-2 gap-2'>
              <h2 className='font-bold mt-4'><span><AttachMoneyIcon /></span>Payment Option</h2>
              <label>
                <input type="radio" name="cb" id="checkbox1" value="Cash on Delivery / Pick Up" required onChange={() => handleChange("Cash-on-Delivery")}/>
                {" "} Cash-on-Delivery
                </label>
                <label>
                
                <input type="radio" name="cb" id="checkbox2" value="Cashless (E-wallets & Bank Payment)"  onChange={() => handleChange("Cashless Payment")} />
                {" "} Cashless Payment
                </label>
            </div>
            { products.map((item)=>(
              <div>
            <div className='border-[1px] mt-4 flex'>
              
              <label>Total Price: {" "}</label>
              <p className='font-bold'>
                
              {" "} {item.price * item.quantity}.00
              </p>
              <label className='absolute ml-[280px] mdl:ml-[430px]'>Php</label>
            </div>
            
                </div>
                ))}
                { loading && (
                        <div className='flex justify-center'>
                            <ProgressBar
                            height="80"
                            width="80"
                            ariaLabel="progress-bar-loading"
                            wrapperStyle={{}}
                            wrapperClass="progress-bar-wrapper"
                            borderColor = '#ffa721'
                            barColor = '#017a3f'
                            />
                        </div>
                    )
                   }
             
                <div className='-mt-4 items-center text-center'>
                <button type='submit' className='mdl:w-[250px] w-[120px] font-titleFont 
            text-xl font-medium text-farmlink_green  border-[2px] border-farmlink_green
                py-2 rounded-md mt-7 mb-2 hover:bg-farmlink_yellow'>
                  Place Order
                </button>
                </div>
             
                
                
                
                 </form>
          </div>

        </div>
      </div>
        ) : (
          
        <motion.div 
        initial={{y:70, opacity:0}}
        animate={{y:0, opacity:1}}
        transition={{delay: 0.5,duration:0.5}}            
        className='flex justify-center items-center gap-4 py-10'>
          <div>
            <img className='w-80 rounded-lg p-4 mx-auto' src={emptycart} alt='emptycartImg' />
            </div>
            <div className='w-full h-[100px] mdl:h-full mdl:w-96 p-4 bg-white flex flex-col items-center rounded-md shadow-lg'>
            {
              products.map((item)=>(
            <div className='flex flex-col p-2 absolute'>
              <p className='-mt-[20px] ml-5'>Checkout one product at a time please.{" :)"}</p>

            <button onClick={()=>dispatch(resetCart(item.id),
                setTimeout(() => {
                  navigate("/");
                  }, 100))}     
                  className='mt-10 mdl:mt-6 bg-[#ffa721] rounded-md cursor-pointer hover:bg-[#d98d1c] active:bg-[#d98d1cd7]
                px-8 py-2 font-titleFont font-semibold text-sm mdl:text-lg'>Reset Checkout and Go Back to Home</button>
                    </div>
              ))}
            </div>
          </motion.div>
      )}
    </div>
    ):(
      <div className='w-full h-[100px] flex items-center justify-center'>
      <p className='font-bold'>Create an Account before accessing this page</p>
      </div>
    )}
    </div>
  );
}

export default Cart