import React from 'react';
import "./individualproducts.css";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import { useState, useEffect } from 'react';
import CloseIcon from "@mui/icons-material/Close";
import { db } from '../../firebase.config';
import { getAuth } from 'firebase/auth';
import { useDispatch } from 'react-redux';
import { addToCart } from '../../redux/farmlinkSlice';
import {useNavigate} from "react-router-dom";
import { useSelector } from 'react-redux';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Slider from 'react-slick';


export const IndividualProduct = ({individualProduct}) => {
  const userInfo = useSelector((state) => state.farmlinkReducer.userInfo)

  const [userExists, setUserExists] = useState(false);

    useEffect(() => {
      if(auth.currentUser){
      
      const subscriber = db.collection('user').doc(auth.currentUser.uid)
        .onSnapshot((doc) => {
          setUserExists(!doc.empty)
        });
        // return cleanup function
      return () => subscriber();
      }
  
      
    },[]); // empty dependencies array => useEffect only called once

    
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const auth = getAuth();
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    

    const str = individualProduct.productLocation;
    const first2 = str.slice(0, 25)

    const notAvailable = () =>{
      alert("Access this feature in our mobile app AniDelivery!")
    }

    function gotocheckout(){
      if(userExists){
      setTimeout(() => {
        navigate("/checkout");
        }, 500)
      }
      else{
        navigate("/signin")
      }
    }

    const handleClickToOpen = () => {
        setOpen(true);
        setLoading(false);

        
    };
 
    const handleToClose = () => {
        setOpen(false);
        
    };

    const [posts, setPosts] = useState([]);

    useEffect(() => {
      if(auth.currentUser){
      const getPostsFromFirebase = [];
      const subscriber = db.collection('user').doc(auth.currentUser.uid)
      .collection('shippingAddress').doc(auth.currentUser.uid)
        .onSnapshot((doc) => {
          
            getPostsFromFirebase.push({
              ...doc.data(), //spread operator
              key: doc.id, // `id` given  by Firebase
              
            });

          
          setPosts(getPostsFromFirebase);
          setLoading(false);
        });
        // return cleanup function
      return () => subscriber();
      }
  
      
    },[]); // empty dependencies array => useEffect only called once

  return (
    <div className=''>
      <div>
            <Dialog open={open} onClose={handleToClose}>
            <DialogActions className=''>
                    <Button onClick={handleToClose}>
                        <span><CloseIcon sx={{ fontSize: 20 }}/></span>
                    </Button>
                </DialogActions>
                <DialogContent>
                <span className='text-xs capitalize italic top-[50px] text-gray-500'>
            {individualProduct.productType}</span>
               {/*  <img className='w-[450px] h-[300px]'src={individualProduct.images[0]} alt="product-img"/> */}

                <Slider className='mdl:w-[450px]'>
          <div>
          <img className='w-[450px] h-[300px]' src={individualProduct.images[0]} alt='one'></img>
          </div>
          <div>
          <img className='w-[450px] h-[300px]' src={individualProduct.images[1]} alt='two'></img>
          </div>
          

        </Slider>
                
                <div id='sellerproduct' className='items-center justify-center gap-6 xl:gap-6 px-2'>
        <div key={individualProduct.uid} className='bg-white w-[full] pt-8 relative flex flex-row gap-2'>

        
        <div className=''>
        <p className='text-xl font-bold mb-6 -mt-6'>{individualProduct.name}</p>
        

            <span className='text-xs capitalize absolute top-9 ml-[1px] text-black'>
              {individualProduct.productVariety}</span>
            
       

        <p className='text-farmlink_yellow font-bold'>₱{" "}{individualProduct.price} per kg</p>
        <p className='text-black font-bold'><span><LocationOnIcon /></span>{individualProduct.productLocation}</p>


        <div className='border-[2px] w-[275px] h-[120px] mdl:w-[425px] mdl:h-[120px] mdl:ml-[10px] -ml-[10px] mt-2 mb-2 flex flex-col '> 
          <img className='w-[100px] h-[100px] mt-2' src={individualProduct.seller.imagePath || "https://www.pngitem.com/pimgs/m/146-1468479_my-profile-icon-blank-profile-picture-circle-hd.png"} />
          </div>
          <div className='flex flex-col items-center text-center -mt-[125px]'>
           
            <a className='text-sm mt-2 font-bold items-center text-center justify-center ml-[40px]'>{individualProduct.seller.fullName}</a>
            <a className='-mt-4 ml-[40px] text-xs'><br></br>Seller</a>
            
          <button className="w-[100px] mdl:w-[120px] h-[50px] bg-[#ffa721] rounded-md 
                          py-2 font-semibold cursor-pointer hover:bg-[#d98d1c] mt-[10px] mdl:mt-2 mdl:ml-[50px] ml-[50px]" 
                          onClick={notAvailable} ><span className='font-titleFont'>
            Message</span></button>
            </div>
          </div>
      
            
            </div>
            </div>
        <div className='items-center justify-center flex flex-col pt-10'>

        
        <button id='addtocart'onClick={()=>dispatch(addToCart({
                  id:individualProduct.uid,
                  title:individualProduct.name,
                  price:individualProduct.price,
                  category:individualProduct.productType,
                  variety:individualProduct.productVariety,
                  image:individualProduct.images[0],
                  sellerId:individualProduct.seller.userId,
                  productId:individualProduct.id,
                  sellerEmail: individualProduct.seller.email,
                  sellerFullName: individualProduct.seller.fullName,
                  sellerContactNumber: individualProduct.seller.contactNumber,
                  quantity:1,

                }),gotocheckout())} 
                  className='w-[200px] font-titleFont text-xl font-medium text-white bg-farmlink_green
                py-2 rounded-md -mt-2 hover:bg-farmlink_yellow'>
                  Buy
                </button>
                </div>
        
                </DialogContent>
                
            </Dialog>
        </div>
    <div id='product' className='flex flex-wrap gap-10 xl:gap-8 px-1 pt-4 mdl:-ml-0 -ml-[20px] mdl:w-full mdl:h-full h-[200px] w-[200px]'>
        <div key={individualProduct.uid} className='bg-white border-[1px] border-gray-200 py-8 z-30 
          hover:border-transparent shadow-none hover:shadow-testShadow duration-200 
          relative flex flex-col gap-0 mdl:ml-[27px] ml-[37px]'>
        <span className='text-xs capitalize italic absolute top-2 right-2 text-gray-500'>
            {individualProduct.productType}</span>
        <div className='items-center'>

            <img className='w-[275px] h-[250px]  hover:cursor-pointer'
            onClick={handleClickToOpen} src={individualProduct.images[0]} alt="product-img"/>
        </div>
        
        <div className='ml-[25px] -mb-4'>
        <div className='font-bold'>{individualProduct.name}</div>
        <div className='font-bold text-xs'>{individualProduct.productVariety}</div>

        <div className='text-farmlink_green font-bold'>₱{" "}{individualProduct.price}/kg</div>
        <p className='text-black font-bold'><span className='-ml-2'><LocationOnIcon /></span>{first2}</p>
        <div className='product-text Quantity'>Stock: {individualProduct.quantity}.0{" "}kg</div>

        </div>
                </div>
    </div>
    </div>
  )
}
