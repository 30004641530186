import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
  ScrollRestoration,
} from "react-router-dom";

import Header from "./components/header/Header";
import "./css/App.css";
/* import { ProductsData } from "./api/api"; */
import Footer from "./components/footer/Footer";
import Signin from "./pages/Signin";
import { Registration } from "./pages/Registration";
import Cart from "./pages/Cart";
import PgFOF from "./components/pgFOF";
import CropsProduct from "./components/CropsProduct";
import VegetablesProduct from "./components/VegetablesProduct";
import Profile from "./pages/Profile";
import RegisterFarmer from "./components/RegisterFarmer";
import { Shop } from "./pages/Shop";
import { AddProducts } from "./pages/AddProducts";
import { Home } from "./pages/Home";
/* import ProductView from './components/ProductView'; */
import FruitsProduct from "./components/FruitsProduct";
import { UserAddress } from "./components/UserAddress";
import { AddAddress } from "./pages/AddAddress";
import { MyOrders } from "./pages/MyOrders";
/* import { Forum } from "./pages/Forum";
import { Chat } from "./pages/Chat"; */
import NotAvailable from "./pages/NotAvailable";
import { EditProfile } from "./pages/EditProfile";
import AboutUs from "./pages/AboutUs";
import Help from "./pages/Help";

const Layout =()=>{
  return (
    <div>
      <Header />
      <ScrollRestoration />
      <Outlet />
      <Footer />
    </div>
  )
}

function App() {
  const router = 
  
  createBrowserRouter(
    createRoutesFromElements(
      
      <Route>
       
      <Route exact path="/" element={<Layout />}>
      <Route index element={<Home />}></Route>
        <Route path="/checkout" element={<Cart />}></Route>
        <Route path="/profile" element={<Profile />}></Route>
        <Route path="/not-found" element={<PgFOF />}></Route>
        <Route path="/crops" element={<CropsProduct />}></Route>
        <Route path="/vegetables" element={<VegetablesProduct />}></Route>
        <Route path="/fruits" element={<FruitsProduct />}></Route>
        <Route path="/farmerregistration" element={<RegisterFarmer />}></Route>
        <Route path="/useraddress" element={<UserAddress />}></Route>
        <Route path="/adduseraddress" element={<AddAddress />}></Route>
        <Route path="/shop" element={<Shop />}></Route>
        <Route path="/addproducts" element={<AddProducts />}></Route>
        <Route path="/myorders" element={<MyOrders />}></Route>
        {/* <Route path="/editprofile" element={<EditProfile />}></Route> */}
        {/* <Route path="/forum" element={<Forum />}></Route>
        <Route path="/chat" element={<Chat />}></Route> */}
        <Route path="/notavailable" element={<NotAvailable />}></Route>
        <Route path="/aboutus" element={<AboutUs />}></Route>
        <Route path="/help" element={<Help />}></Route>
        {/* <Route path="/product-details/:id" element={<ProductView />}></Route> */}
        
        </Route>
        <Route path="/signin" element={<Signin />}></Route>
        <Route path="/registration" element={<Registration />}></Route>
        
      </Route>
  ));
  return (
    <div className="font-bodyFont bg-gray-100">
     <RouterProvider router={router}></RouterProvider>
    </div>
  );
}

export default App;
