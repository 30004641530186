import React, { useEffect, useRef, useState } from 'react'
import MenuIcon from "@mui/icons-material/Menu";
import {motion} from "framer-motion"
import CloseIcon from "@mui/icons-material/Close";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SideNavContent from './SideNavContent';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { db } from '../../firebase.config';


const HeaderBottomTwo = () => {
    const userInfo = useSelector((state) => state.farmlinkReducer.userInfo)
    const auth = getAuth(); 
  /* const user = auth.currentUser; */
  /* const uid = userInfo.uid; */
  /* const [sellerExists, setSellerExists] = useState(false);

  useEffect(() => {
    const unsubscribe = db.collection('SignedUpUsersData').doc(auth.currentUser.uid).collection('SellerInformation')
      .onSnapshot((snapshot) => {
        setSellerExists(!snapshot.empty)
      });
    return unsubscribe;
  }, [auth.currentUser.uid]); */

    const ref=useRef();
    const [sidebar,setSidebar]= useState(false)
    useEffect(()=>{
        document.body.addEventListener("click",(e)=>{
         if(e.target.contains(ref.current)){
            setSidebar(false)
         }
        })
    },[ref,sidebar])

  const [userExists, setUserExists] = useState(false);
  const [posts, setPosts] = useState([]);

    useEffect(() => {
      if(auth.currentUser){
      const getPostsFromFirebase = [];
      const subscriber = db.collection('user').doc(auth.currentUser.uid)
        .onSnapshot((doc) => {
          setUserExists(!doc.empty)
            getPostsFromFirebase.push({
              ...doc.data(), //spread operator
              key: doc.id, // `id` given  by Firebase
            });
          
          setPosts(getPostsFromFirebase);
        });
        // return cleanup function
      return () => subscriber();
      }
      
    },[auth.currentUser]); // empty dependencies array => useEffect only called once
    
  return (
    <div className="w-full px-4 h-[36px] bg-farmlink_green z-100 text-white flex items-center">
      {/* ============ ListItems Start here ============ */}
      <ul className="flex items-center gap-0 text-sm tracking-wide">
        <li
          onClick={() => setSidebar(true)}
          className="headerHover px-2 py-1 flex items-center gap-1 
          hover:cursor-pointer border border-transparent hover:border-white"
        >
          <MenuIcon />
          All
        </li>
       {/*  <li className="headerHover hidden md:inline-flex px-2 
        py-1.5 hover:cursor-pointer border border-transparent hover:border-white">Today's Deals</li>
        <li className="headerHover hidden md:inline-flex px-2 
        py-1.5 hover:cursor-pointer border border-transparent hover:border-white">Customer Support</li> */}
       {/*  { sellerExists ? (<li></li>) : (<Link to='/farmerregistration'><li className="headerHover hidden md:inline-flex px-2 
          py-1.5 hover:cursor-pointer border border-transparent hover:border-white">Become a Seller</li></Link>)
          } */}
        {
          userExists ? ( <li></li>
        
          ) : ( <Link to='/registration'>
          <li className="headerHover hidden md:inline-flex px-2 
          py-1.5 hover:cursor-pointer border border-transparent hover:border-white">Registry</li>
          </Link>)
        }
      </ul>
      {/* ============ ListItems End here ============== */}
      {/* ============ sideNav Start here ============== */}
      {sidebar && (
        <div className="w-full h-screen text-black fixed top-0 left-0 bg-black bg-opacity-50">
          <div className="w-full h-full relative">
            <motion.div ref={ref} initial={{x:-500,opacity:0}} animate={{x:0, opacity:1}} transition={{duration:.5}}
            className="w-[80%] md:w-[350px] h-full bg-white border border-black">

              <div className="w-full bg-farmlink_green text-black py-2 px-6 flex items-center gap-4">
                {
                  userExists ? (
                    posts.map((post) => 
                    <Link to='/profile'>
                    <img className='w-[42px] h-10 rounded-full cursor-pointer'
                    src={post.imagePath || "https://www.pngitem.com/pimgs/m/146-1468479_my-profile-icon-blank-profile-picture-circle-hd.png"}
                    alt='UserImg'
                    />
                    </Link>
                  )) : (
                <AccountCircleIcon />
                  )}
                  <span onClick={()=>setSidebar(false)} className="cursor-pointer absolute 
            top-2 left-[230px] md:left-[294px] w-10 h-10 text-black flex items-center justify-center hover:text-white duration-300">
              <CloseIcon />
            </span>
                {
                  userExists ? (
                  posts.map((post) =>
                  <h3 className="font-titleFont text-white font-bold text-lg tracking-wide">
                  {post.fullName}
                </h3> 
                )):( 
                <h3 className="font-titleFont text-white font-bold text-lg tracking-wide">
                  Hello, Sign In
                </h3>

                )}
              </div>
              <SideNavContent
                title="Product Categories"
                one="Crops"
                two="Vegetables"
                three="Fruits"
                four="Organics"
                five="Spices"
                six="Dry Fruits"
                seven="Seeds"
                eight="Fertilizers"
                nine="Plants"
                ten="Grass"
                eleven="Others"
                twelve="View All"
              />
            </motion.div>
          </div>
        </div>
      )}
      {/* ============ sideNav End here ================ */}
    </div>
  );
}
export default HeaderBottomTwo

