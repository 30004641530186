import React from 'react'
import { useState } from 'react';
/* import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'; */
/* import { getAuth } from 'firebase/auth'; */
import {storage, db} from '../../firebase.config';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from "@material-ui/core/Dialog";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import { motion } from 'framer-motion';
import { getFirestore, doc, updateDoc } from "firebase/firestore";
import { ProgressBar } from "react-loader-spinner";

/* const auth = getAuth(); */
export const SellerIndividualProducts = ({sellerIndividualProducts}) => {

   //loading
    const [loading, setLoading] = useState(false);
    const [successMsg, setSuccessMsg] = useState("");

    //product
    /* const [productDescription, setProductDescription] = useState(''); */
    const [productPrice, setProductPrice] = useState(0);
    const [productQuantity, setProductQuantity] = useState(0);
    const [error, setError] = useState('');

    //product image
    const types = ['image/png','image/jpeg','image/jpg'];
  /* console.log(sellerIndividualProducts); */

  const uid = sellerIndividualProducts.uid;
  
/* function removeItem(){
  
const subcollectionRef = db.collection('Products').doc(sellerIndividualProducts.uid);
subcollectionRef.delete().then(() => {
  console.log('Document successfully deleted!');
 
}).catch((error) => {
  console.error('Error removing document: ', error);
})
} */
const [open, setOpen] = useState(false);
 
    const handleClickToOpen = () => {
        setOpen(true);
        setLoading(false);
        setSuccessMsg("");
        
    };
 
    const handleToClose = () => {
        setOpen(false);
        setLoading(false);
        setSuccessMsg("");
    };


  function removeItem(){
  
    const subcollectionRef = db.collection('Products').doc(uid);
    subcollectionRef.delete().then(() => {
      console.log('Document successfully deleted!');
     
    }).catch((error) => {
      console.error('Error removing document: ', error);
    })
    setLoading(true)
    setSuccessMsg("Product Successfully Deleted!");
                setTimeout(() => {
                  setOpen(false);
                  window.location.reload();
                  }, 1000);
    }

    function deleteconfirmation() {

      if (confirm("Are you sure you want to delete this product?") == true) {
        removeItem();
      } else {
        console.log("You Cancelled!")
      }
    }


    const editproduct = (e) => {
      
      const docRef = doc(db, "Products", uid);
      e.preventDefault();
        /* console.log(productName, productPrice, productImg) */
        /* const uploadTask = storage.ref(`product-images/${productImg.name}`).put(productImg);
        uploadTask.on('state_changed', snapshot => {
            const progress = (snapshot.bytesTransferred/snapshot.totalBytes) * 100;
            console.log(progress);
        }, err=> {
            setError(err.message)
        },()=>{ */
            /* storage.ref('product-images').child(productImg.name).getDownloadURL().then(url=>{ */
                db.collection('Products')
                updateDoc(docRef,{
                  quantity: Number(productQuantity),
                  price: Number(productPrice),
                })
                .then(docRef => {
                  setSuccessMsg("Product Successfully Updated!");
                  console.log("Value of an Existing Document Field has been updated");
              })
              .catch(error => {
                console.log(error);
            })
            setLoading(true)
            setTimeout(() => {
              setOpen(false);
              window.location.reload();
              }, 1000);
    }

  return (
    
    <div className=''>
      <div>
            <Dialog open={open} onClose={handleToClose}>
            <DialogActions className='-mb-10'>
                    <Button onClick={handleToClose}
                        color="primary" autoFocus>
                        Close
                    </Button>
                </DialogActions>
                {/* <DialogTitle>{"Edit Product"}</DialogTitle> */}
                <DialogContent>
                    {/* <DialogContentText>
                        
                    </DialogContentText> */}
                    
                <form autoComplete="off" className='mdl:w-[500px] mx-auto flex flex-col items-center'
                 onSubmit={editproduct}>
                <div className='w-full rounded-tr-md rounded-br-md rounded-tl-md rounded-bl-md p-6 bg-white'>
                <br/>
                <h2 className='font-titleFont text-2xl font-bold mb-4 -mt-4'>{" "}Edit Product</h2>

                
                <div id='sellerproduct' className='items-center flex flex-col mdl:flex-row justify-center gap-6 xl:gap-6 px-2'>
        <div key={sellerIndividualProducts.uid} className='bg-white mdl:w-[400px] 
        border-[1px] border-gray-200 mdl:py-8 relative flex flex-row gap-2 mb-7 mdl:ml-4'>
        <span className='text-xs capitalize italic absolute top-2 right-2 text-gray-500'>
            {sellerIndividualProducts.productType}</span>
        <div className='items-center text-center'>
            <img className='mdl:w-[150px] mdl:h-[150px] mdl:ml-6 mdl:mt-0 mt-16'src={sellerIndividualProducts.images[0]} alt="product-img"/>
        </div>
        <div className='mdl:ml-[25px] -mb-4 w-[200px]'>
        <p className='font-bold mt-6'>{sellerIndividualProducts.name}</p>
        <p className='text-base'>Per Kilogram</p>
        {/* <div className='product-text Description'>{individualProduct.ProductDescription}</div> */}
        <p className='text-farmlink_yellow font-bold'>₱{" "}{sellerIndividualProducts.price} per Kilogram</p>
        <p className='product-text Quantity'>Item Quantity: {sellerIndividualProducts.quantity}{" "}kg</p>
        
                        </div> 
                        </div>
                        </div>
                <div className='flex flex-col gap-3'>
                  <div className='flex flex-col gap-2'>

                    <p className='text-sm font-bold'>Product Price per Kilogram</p>
                  <input type='number' className='w-full py-1 border border-zinc-400 px-2 text-base 
                    rounded-sm outline-none focus-within:border-farmlink_yellow 
                    focus-within:shadow-farmlinkInput duration-100' 
                    placeholder='Enter Price per Kilogram' required onChange={(e) => 
                    setProductPrice(e.target.value)}></input>

                    <p className='text-sm font-bold'>Product Quantity (kg)</p>
                  <input type='number' className='w-full py-1 border border-zinc-400 px-2 text-base 
                    rounded-sm outline-none focus-within:border-farmlink_yellow 
                    focus-within:shadow-farmlinkInput duration-100' 
                    placeholder='Enter Item Qty. in Kilograms' required  onChange={(e) => 
                    setProductQuantity(e.target.value)}></input>
        
                    {/* <h2 className='text-sm font-bold'>Add Product Image:</h2>
                    <input type="file" id='files' required onChange={productImgHandler} /> */}
                    {/* <img src={productImg} /> */}
                    {error&&<span>{error}</span>}
                </div>
                </div>
                {
                    successMsg && (
                        <div className='mt-10'>
                            <motion.p 
                            initial={{ y: 10, opacity: 0}}
                            animate={{ y:0, opacity: 1}}
                            transition={{duration: 0.5}}
                            className='text-base font-titleFont font-semibold text-green-500 border-[1px]
                            border-green-500 px-2 text-center'
                            >{successMsg}</motion.p>
                        </div>
                    )
                }
                   { loading && (
                        <div className='flex justify-center'>
                            <ProgressBar
                            height="80"
                            width="50"
                            ariaLabel="progress-bar-loading"
                            wrapperStyle={{}}
                            wrapperClass="progress-bar-wrapper"
                            borderColor = '#ffa721'
                            barColor = '#017a3f'
                            />
                        </div>
                    )
                   }
                    <div className='items-center text-center'>
                    <button className="mdl:w-[400px] w-[150px] h-[40px] bg-[#ffa721] rounded-md 
                          py-2 mt-2 font-semibold cursor-pointer hover:bg-[#d98d1c] mdl:ml-[25px]" 
                          type='submit' >
                              Save Edit
                          </button>
                          
                          </div>
                    <div className='items-center text-center'>
                    <button className="mdl:w-[400px] w-[150px] h-[40px] bg-red-500 rounded-md 
                          py-2 mt-2 font-semibold cursor-pointer hover:bg-red-700 mdl:ml-[25px]" 
                          onClick={deleteconfirmation}>
                              Delete Item
                          </button>
                        
                          
                          </div>
                    </div>
                    
                </form>
                </DialogContent>
                
            </Dialog>
        </div>
    <div id='sellerproduct' className='items-center justify-center gap-6 xl:gap-6 px-4 pb-2'>
        <div key={sellerIndividualProducts.uid} className='bg-white -mt-6 mdl:w-[700px] w-[300px] mdl:h-[full] h-[full]
        border-[1px] border-gray-200 py-8 relative flex flex-row gap-2 mb-7'>
        <span className='text-xs capitalize italic absolute top-2 right-2 text-gray-500'>
            {sellerIndividualProducts.productType}</span>
        <div className='items-center'>
            <img className='w-[150px] h-[150px] ml-5'src={sellerIndividualProducts.images} alt="product-img"/>
        </div>
        <div className='ml-[25px] -mb-4 w-[200px]'>
        <p className='font-bold mt-6'>{sellerIndividualProducts.name}</p>
        <p className='font-light text-base -mt-0'>{sellerIndividualProducts.productVariety}</p>


        <p className='text-base font-light'>Per Kilogram</p>
        {/* <div className='product-text Description'>{individualProduct.ProductDescription}</div> */}
        <p className='text-farmlink_yellow font-bold'>₱{" "}{sellerIndividualProducts.price} per Kilogram</p>
        <p className='font-light text-xl mdl:text-lg'>Item Quantity: {sellerIndividualProducts.quantity}{" "}kg</p>
        <p className='font-bold text-xl mdl:text-lg text-farmlink_green'>Status: {sellerIndividualProducts.status}</p>
        
    </div> 
    <div>
      
      <button className='mdl:ml-[250px] mt-[60px]' onClick={handleClickToOpen}>
          <span className='text-gray-500 hover:text-green-500'><EditIcon sx={{ fontSize: 30 }}/></span>
          </button>
         
        </div>
    </div>
    </div>
    </div>
  )
}