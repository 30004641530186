import React from 'react'

const Help = () => {
  return (
    <div
    className='flex flex-row justify-center items-center pt-6 pb-6'>
      <div className='mdl:w-[50%] h-[600px] flex flex-col items-center bg-white overflow-y-scroll rounded-md px-4'>
        <h2 className='text-xl font-bold'>Help</h2>
        <br />
        <h2 className='text-xl font-bold'>TERMS AND CONDITIONS</h2>
        <br />
        <p className='text-sm'align='justify'>
        By accessing or using the Website, you agree to be bound by these Terms. If you do not agree with any part of these Terms, 
        you should not use the Website.
        <br />1. Website Usage <br /><br />a. The purpose of the Website is to allow people or organizations that are involved 
        in agriculture (collectively, "Users") to communicate and work together. <br />b. To improve Users' agricultural operations, the 
        Website offers features including information sharing, marketplace, messaging, and collaboration tools.
 <br />2. Accounts for Users <br /><br />a. Users must set up an account in order to use the Website. Users are solely responsible for 
 all activities that take place under their accounts and are required to keep the passwords to their accounts secure. <br />b. Users undertake 
 to complete the registration process with truthful and current information and to keep their account information up to date.
 <br /><br /> 3. User Behaviour <br />
 <br />a. Users must abide by all applicable rules and regulations when using the Website.  <br />
b. Users undertake to refrain from any actions that could damage, deactivate, or otherwise affect the functionality of the Website.
<br />c. Users agree not to upload or publish any content that is unlawful, offensive, infringing, or violates the rights of other parties 
and acknowledge that they are entirely liable for any content they upload, distribute, or transmit through the Website. 
<br /> <br />4. Inventive Property <br /><br />a. The Company or its licensors own the intellectual property rights to the Website and 
any related material, including but not limited to text, graphics, logos, and software. <br />b. A restricted, non-exclusive, and non-transferable 
license is provided to Users to use the Website for the purposes for which it was designed. Without the Company's prior written consent, 
Users may not copy, modify, distribute, or generate derivative works based on the Website.
<br /> <br /> 5. Privacy <br /><br />a. The company honors the users' right to privacy. The AniDelivery's Privacy Policy governs the gathering, 
use, and sharing of personal data through the Website. <br />b. Users agree to the collection, use, and sharing of their personal information 
as outlined in the Privacy Policy by using the Website.
<br /> <br />6. Termination:<br /> <br />a. Without warning and for any reason, including but not limited to a User's violation of these Terms, 
the AniDelivery may, in its sole discretion, terminate or suspend the User's use of the Website. <br />b. By following the account termination 
instructions provided in the Website, users can cancel their accounts whenever they want.
<br /> <br />7. Liability Limitation: <br /><br />a. Indirect, incidental, consequential, and punitive damages resulting from or connected with 
the use or inability to use the Website are not covered by the AniDelivery's liability policy. <br />b. Users agree that there may be 
restrictions, delays, and other issues with the Website that are typical of online Websites, and that the AniDelivery is not liable 
for any such problems.
<br /> <br />8. Amendments to the Terms: <br /><br />a. These Terms are subject to change at any time by the AniDelivery. Any significant 
modifications to the Terms will be communicated to Users. <br />b. After the Terms have been revised, using the Website afterward constitutes
 acceptance of the new Terms.
<br /> <br /> 9. Regulating Law: <br /><br />a. Without taking into account its rules on conflict of laws, the laws of the country in 
which the AniDelivery is based shall govern these Terms and be followed in their interpretation.<br /> b. The courts in the country where 
the AniDelivery is based shall have exclusive jurisdiction over any disputes arising out of or related to these Terms. Please carefully read
 these terms. You accept that you have read, understand, and agree to be governed by these Terms and any other policies posted on the AniDelivery 
 Website by using it. 
<br /> <br />10. The AniDelivery Website is intended for famers who owns 2 hectares or more of land.</p>
<br /><br />

<h2 className='text-xl font-bold'>PRIVACY POLICY</h2>
<br />
<p className='text-sm' align='justify'>
At AniDelivery, we are dedicated to  maintaining the security of your personal  information as well as your right to privacy.  
When you use the AniDelivery Website,  we collect, utilize, and secure your  information as described in this privacy  statement. 
You accept the terms and  conditions of this Privacy Policy by using  the Website.

<br /><br />1.	Information That We Gather: <br /><br />1.1 Personal Information: While using the Website,  we may gather certain personally
 identifiable data,  
such as your name, email address, phone number, and  location. We may also compile any other information you  freely supply, such as the 
specifics of your farm or business,  your preferences, and other pertinent data. <br /><br />1.2 Usage Information: We may 
gather anonymous data  on how 
you use the Website, including the kind of  device you're using, the operating system, the type of  browser you're using, your IP address,
 and the actions  you perform there. Using log files, cookies, and other  similar technologies, this data is gathered.  <br /><br />2. 
 Information Use:<br /><br /> 2.1 
 Providing Services: We employ the information  gathered to deliver and enhance the services made  available through the Website. 
 This entails fostering  user interaction, delivering tailored recommendations,  and enhancing the user experience. <br /><br />2.2 Communication: We 
 may contact you via phone or  email to provide updates, bulletins, offers, and other  information about the Website. By following the  
 unsubscribe instructions included in the communication,  you can choose not to receive these communications<br /><br /> 2.3 Aggregated Data: In order 
 to produce statistical and  analytical insights, we may combine and anonymize the  gathered data. You cannot be personally identified from 
  this aggregated data, which can be used for a variety of  things like research, analysis, and service improvement.<br /><br /> 2.4 Legal Compliance: 
  As required by law, legal processes,  or to protect our rights, property, or safety as well as the  rights, property, or safety of our users 
  or others, we may  use and disclose your information.  <br /><br />3. Data Protection: We put in place the necessary organizational and  technical safeguards
   to maintain the security of your  personal data and guard against loss, theft, and alteration  by unauthorized parties. The absolute security
    of any data  transit or storage system, however, cannot be guaranteed.  As a result, even though we make every effort to  safeguard your 
    information, we cannot ensure its total  security.  <br /><br />4. Sharing with third parties: Your information might be disclosed to dependable  outside 
    service providers who help us run the Website  and offer our services. These service providers are bound  by contract to protect the
     privacy and security of your  information and to use it only as directed by us.  <br /><br />5. Privacy of Children: Individuals under the age of 13 
     should not use the  Website. We do not knowingly assemble personal  data from minors under the age of 13. Please contact us  if you 
     think we might have any information from or about  a child under 13; we'll take immediate action to remove it.  <br /><br />6. The Privacy Policy has 
     changed: We might occasionally change our privacy statement.  The amended version will go into effect on the date that  is noted at the 
     start of the Privacy Policy. We encourage  you to frequently review the Privacy Policy so that you are  aware of how we collect, utilize, 
     and safeguard your  information.  <br /><br />7. Contact Us: Please get in touch with us  if you have any queries, complaints, or suggestions  regarding 
     this privacy statement or our privacy practices.

</p><br /><br />
        </div>
        </div>
  )
}

export default Help