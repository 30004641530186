export const allItems=[
        
    {_id:"100",title:"All Districts"}, 
    {_id:"101",title:"District 1"},
    {_id:"102",title:"District 2"},
    {_id:"103",title:"District 3"},
    {_id:"104",title:"District 4"},
];
export const middleList = [
    {
      _id: 2221,
      title: "CUSTOMER SERVICE",
      listItem: [
        {
          _id: "001",
          listData: [
            "Help Centre",
            /* "AniDelivery Cares PH",
            "Payment Methods",
            "AniDelivery Pay",
            "AniDelivery Coins",
            "Order Tracking",
            "Free Shipping",
            "Return & Refund",
            "AniDelivery Guarantee",
            "Contact Us", */
          ],
        },
      ],
    },
    {
      _id: 2222,
      title: "ABOUT ANIDELIVERY",
      listItem: [
        {
          _id: "002",
          listData: [
            "About Us"
          ]
        },
      ],
    },
    /* {
      _id: 2223,
      title: "PAYMENT",
      listItem: [
        {
          _id: "003",
          listData: [
            
          ],
        },
      ],
    }, */
    {
      _id: 2224,
      title: "FOLLOW US",
      listItem: [
        {
          _id: "004",
          listData: [
            "Facebook",
            
            "Instagram",
            
            /* "Twitter",
            
            "LinkedIn", */
          ],
        },
      ],
    },
    /* {
      _id: 2225,
      title: "ANIDELIVERY APP DOWNLOAD",
      listItem: [
        {
          _id: "004",
          listData: [
            
          ],
        },
      ],
    } */
  ];
  export const footerBottomItem = [
    {
      _id: 1001,
      title: "Amazon Music",
      des: "Stream millions of songs",
    },
    {
      _id: 1002,
      title: "	Amazon Advertising",
      des: "Find, attract, and engage customers",
    },
    {
      _id: 1003,
      title: "Amazon Drive",
      des: "Cloud storage from Amazon",
    },
    {
      _id: 1004,
      title: "6pm",
      des: "Score deals on fashion brands",
    },
    {
      _id: 1005,
      title: "AbeBooks",
      des: "Books, art & collectibles",
    },
    {
      _id: 1006,
      title: "ACX",
      des: "Audiobook Publishing Made Easy",
    },
    {
      _id: 1007,
      title: "Sell on Amazon",
      des: "Start a Selling Account",
    },
    {
      _id: 1008,
      title: "Amazon Business",
      des: "Everything For Your Business",
    },
    {
      _id: 1009,
      title: "AmazonGlobal",
      des: "Ship Orders Internationally",
    },
    {
      _id: 1010,
      title: "Home Services",
      des: "Experienced Pros appiness Guarantee",
    },
    {
      _id: 1011,
      title: "Amazon Ignite",
      des: "Sell your original Digital Educational Resources",
    },
    {
      _id: 1012,
      title: "Amazon Web Services",
      des: "Scalable Cloud Computing Services",
    },
    {
      _id: 1013,
      title: "Audible",
      des: "Listen to Books & Original Audio Performances",
    },
    {
      _id: 1014,
      title: "Book Depository",
      des: "Books With Free Delivery Worldwide",
    },
    {
      _id: 1015,
      title: "Box Office Mojo",
      des: "Find Movie Box Office Data",
    },
    {
      _id: 1016,
      title: "ComiXology",
      des: "Thousands of Digital Comics",
    },
    {
      _id: 1017,
      title: "DPReview",
      des: "Digital Photography",
    },
    {
      _id: 1018,
      title: "Fabric",
      des: "Sewing, Quilting & Knitting",
    },
    {
      _id: 1019,
      title: "Goodreads",
      des: "Book reviews & recommendations",
    },
    {
      _id: 1020,
      title: "IMDb",
      des: "Movies, TV & Celebrities",
    },
    {
      _id: 1021,
      title: "	IMDbPro",
      des: "Get Info Entertainment Professionals Need",
    },
    {
      _id: 1022,
      title: "Kindle Direct Publishing",
      des: "Indie Digital & Print Publishing Made Easy",
    },
    {
      _id: 1023,
      title: "Prime Video Direct",
      des: "Video Distribution Made Easy",
    },
    {
      _id: 1024,
      title: "Shopbop",
      des: "Designer Fashion Brands",
    },
    {
      _id: 1025,
      title: "Woot!",
      des: "Deals and Shenanigans",
    },
    {
      _id: 1026,
      title: "Zappos",
      des: "Shoes & Clothing",
    },
    {
      _id: 1027,
      title: "Ring",
      des: "Smart Home Security Systems",
    },
    {
      _id: 1028,
      title: "eero WiFi",
      des: "Stream 4K Video in Every Room",
    },
    {
      _id: 1029,
      title: "Blink",
      des: "Smart Security for Every Home",
    },
    {
      _id: 1030,
      title: "Neighbors App",
      des: "Real-Time Crime & Safety Alerts",
    },
    {
      _id: 1031,
      title: "Amazon Subscription Boxes",
      des: "Top subscription boxes - right to your doo",
    },
    {
      _id: 1032,
      title: "PillPack",
      des: "Pharmacy Simplified",
    },
  ];