import React, {useEffect,useState} from 'react';
import { db } from '../firebase.config';
import { getAuth } from 'firebase/auth';
import { doc, updateDoc, deleteField } from "firebase/firestore";
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import { Link } from 'react-router-dom';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import { useSelector } from 'react-redux';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useNavigate } from 'react-router-dom';


export const UserAddress = () => {
  /* console.log(posts) */
  const userInfo = useSelector((state) => state.farmlinkReducer.userInfo)
  const [open, setOpen] = useState(false);
  const auth = getAuth();
  const navigate = useNavigate();

  const [userExists, setUserExists] = useState(false);

    useEffect(() => {
      if(auth.currentUser){
      const subscriber = db.collection('user').doc(auth.currentUser.uid)
        .onSnapshot((doc) => {
          setUserExists(!doc.empty)
        });
        // return cleanup function
      return () => subscriber();
      }
  
      
    },[auth.currentUser]); // empty dependencies array => useEffect only called once

 const [value, setValue] = useState('1');

 const handleChange = (event, newValue) => {
   setValue(newValue);
 };

 const [address, setAddress] = useState([])

  const getUserAddress = async () =>{
    
    if(auth.currentUser){
    const address = await db.collection('user').doc(auth.currentUser.uid).collection('shippingAddress').where(("userId") ,'==', auth.currentUser.uid).get();
    const addressArray = [];
    for (var snap of address.docs){
      var data = snap.data();
      data.uid = snap.id;
      addressArray.push({
        ...data
        
      })

      if(addressArray.length === address.docs.length){
        setAddress(addressArray);
      }
    }
    
  }
}
  useEffect(()=>{
      getUserAddress();
  },[auth.currentUser])

 /* const getUserAddress =  () =>{
  
  if(auth.currentUser){
  const getPostsFromFirebase = [];
  const subscriber =  db
    .collection('user').doc(auth.currentUser.uid).collection('shippingAddress').where("userId" ,'==', auth.currentUser.uid).get()
    .onSnapshot((doc) => {

      if (doc.exists) {
        console.log("Document data:", doc.data());
   } else {
        console.log("No such document!");
      
   }
        getPostsFromFirebase.push({
          ...doc.data(), //spread operator
          key: doc.id, // `id` given  by Firebase
        });
        
      
      setPosts(getPostsFromFirebase);
      
    }); return () => subscriber();
   }}

  // return cleanup function

 useEffect(() => {
  getUserAddress()
},[auth.currentUser]); */


  function removeAddress(){

    /* const cityRef = doc(db, "user", auth.currentUser.uid, "shippingAddress", auth.currentUser.uid); */
    
    /* db.collection("user").doc(auth.currentUser.uid).collection("shippingAddress").where(("userId") ,'==', auth.currentUser.uid).delete().then(() => {
      console.log('Address successfully removed!');
    
      setTimeout(() => {
        location.reload()})}) */
      
        const collectionRef = db.collection("user").doc(auth.currentUser.uid).collection("shippingAddress");
      collectionRef.where(("userId") ,'==', auth.currentUser.uid)
      .get()
      .then(querySnapshot => {
      querySnapshot.forEach((doc) => {
        doc.ref.delete().then(() => {
      /* console.log("Document successfully deleted!"); */
      alert("Address Successfully deleted! Please wait for results to display")
      
      
    },setTimeout(() => {
      navigate("/profile  ")
    }, 1000))})})

      }
  

  function deleteconfirmation() {
  
    if (confirm("Remove this address?") == true) {
      removeAddress();
    } else {
     /*  console.log("You Cancelled!") */
    }
  }

  /* console.log(address.length) */

        return (
          <div>
      {userExists ? (
          <div className='w-full items-center'>
            <div className='items-center justify-center flex flex-col mt-10'>
      
              <Link to="/adduseraddress">
      <button className="w-full h-full mx-auto text-farmlink_green bg-white rounded-md py-1 px-2
        font-semibold cursor-pointer hover:bg-gray-200 border border-farmlink_green">
          <span className='text-farmlink_green'>
            <AddLocationIcon sx={{ fontSize: 40 }}/>
          </span>
          {" "}Add an Address<br></br>
        </button>
        </Link>
            <div className='items-center justify-center pt-12 mb-10'>
            <h1 className='font-bold text-xl mb-2'>Add and Manage Address{"(es)"}</h1>
         {/* sx={{
     "& .MuiTab-root.Mui-selected": { color: "#017a3f" },
     "& .MuiTabs-indicator": { bgcolor: "#017a3f" }
    }} */}
    
    <Box sx={{  maxWidth: { xs: 350, sm: 1000 }, typography: 'body1', "& .MuiTab-root.Mui-selected": { color: "#017a3f" },
     "& .MuiTabs-indicator": { bgcolor: "#017a3f" }}}>
      <TabContext value={value} >
        <Box sx={{ borderBottom: 1, borderColor: '#017a3f' }}>
        </Box>
        <TabPanel value="1" className='bg-gray-200 h-[330px] mdl:w-[700px] overflow-auto'>
       
        {address.length > 0 ? ( 
        
        address.map((address1)=>
                
                <div className='py-2 flex flex-col items-center'>
                    <div className='bg-white w-[300px] mdl:w-[500px] mdl:h-[125px]'>
                    <div key={address1.key} className='gap-4 flex-col mt-0'>
                    <h1 className='font-bold'>{address1.addressType}</h1>
                    <h1 className='font-bold'>{address1.fullNameShipping}</h1>
                    <h1>{address1.phone}</h1>
                    <h1>{address1.street} {address1.barangay}, {address1.city} {address1.postalCode}, {address1.province}</h1>

                    <div className='items-center text-center'>
                      <button className='' onClick={deleteconfirmation}><span className='text-gray-500 hover:text-red-500 -mt-[200px]'>
                        <DeleteOutlineIcon sx={{ fontSize: 30 }}/></span></button>
                        </div>

                        </div>
                      <div>      
                  </div>
                </div>
            </div>

              )) : (
                <h1 className='font-bold text-center'>No Address Added!</h1>
              )}
          
      </TabPanel>
      </TabContext>
    </Box>
    </div>
         
          </div>
          </div>
          ):(
            <div className='w-full h-[100px] flex items-center justify-center'>
            <p className='font-bold'>Create an Account before accessing this page</p>
            </div>
          )}
          </div>
        )
      }
 