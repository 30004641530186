import React, { useState } from "react";
import { logo } from "../assets/index";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Link, useNavigate } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword, signOut} from "firebase/auth";
import { ProgressBar } from "react-loader-spinner";
import { motion } from "framer-motion";


const Signin = () => {

  const auth = getAuth();
  const navigate =useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errEmail, setErrEmail] = useState("");
  const [errPassword, setErrPassword] = useState("");

  const [userEmailErr, setUserEmailErr] = useState("");
  const [userPassErr, setUserPassErr] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");

  const handleEmail =(e) => {
    setEmail(e.target.value);
    setErrEmail("");
  }
  const handlePassword =(e) => {
    setPassword(e.target.value);
    setErrPassword("");
  }
  const handleLogin =(e) => {
    e.preventDefault()
    if (!email) {
      setErrEmail("Enter your email");
      
    }else{
      setUserEmailErr("");
    }
  
   if(!password){
       setErrPassword("Enter your password");
       setUserPassErr("");
   }

   if (email && password){
    setLoading(true)
    
    signInWithEmailAndPassword(auth, email, password)
  .then(() => {
    
      if(auth.currentUser.emailVerified){
        console.log("Email is verified")

        setUserEmailErr("");
    setUserPassErr("");  
    setTimeout(() => {
      navigate("/")
    }, 500);
      }else{
        setUserEmailErr("Please verify your email!");
        setUserPassErr("");
        setLoading(false)
        signOut(auth)
        //console.log("Email not verified yet!")
      }
  })
  .catch((error) => {
    setLoading(false)
    const errorCode = error.code;
    if(errorCode.includes("auth/invalid-email")){
      setUserEmailErr("Invalid Email");
  }
  if(errorCode.includes("auth/wrong-password")){
    setUserPassErr("Wrong password! Try again");
}
setUserEmailErr("Retry with correct credentials! Or Sign Up First!");
setUserPassErr("Retry with correct credentials! Or Sign Up First!");
  console.log("Something is up, Retry with correct credentials!");
  });
    setEmail("")
    setPassword("")
   }
  }
  return (
    <div className="w-full -mt-14">
      <div className="w-full bg-gray-100 pb-10">
        {
          successMsg ? ( 
          <div className="justify-content flex flex-col">
          <motion.p 
          initial={{ y: 10, opacity: 0}}
          animate={{ y:0, opacity: 1}}
          transition={{duration: 0.5}}
          className='text-base font-titleFont font-semibold text-green-500 border-[1px]
          border-green-500 px-2 text-center'
          >{successMsg}
          </motion.p>
          </div> 
          ) : (

          <form className="w-[350px] mx-auto flex flex-col items-center">
          <Link to="/">
            <img
              className="w-[250px] h-[100px] mt-[100px] mb-5"
              src={logo}
              alt="logo"
            />
          </Link>
          <div className="w-full border border-zinc-200 p-6">
            <h2 className="font-titleFont text-3xl font-medium mb-4">
              Sign In
            </h2>
            <div className="flex flex-col gap-3">
              <div className="flex flex-col gap-2">
                <p className="text-sm font-medium">
                  Email address
                </p>
                <input
                  value={email}
                  onChange={handleEmail}
                  className="w-full lowercase py-1 border border-zinc-400 px-2 text-base 
                      rounded-sm outline-none focus-within:border-farmlink_yellow 
                      focus-within:shadow-farmlinkInput duration-100"
                  type="email"
                />
                {errEmail && (
                  <p
                    className="text-red-600 text-xs font-semibold tracking-wide
                            flex items-center gap-2 -mt-1.5"
                  >
                    <span className="italic font-titleFont font-extrabold text-base">
                      !
                    </span>
                    {errEmail}
                  </p>
                )}
                {userEmailErr && (
                  <p
                    className="text-red-600 text-xs font-semibold tracking-wide
                            flex items-center gap-2 -mt-1.5"
                  >
                    <span className="italic font-titleFont font-extrabold text-base">
                      !
                    </span>
                    {userEmailErr}
                  </p>
                )}
              </div>
              <div className="flex flex-col gap-2">
                <p className="text-sm font-medium">Password</p>
                <input
                  value={password}
                  onChange={handlePassword}
                  className="w-full 
                       py-1 border border-zinc-400 px-2 text-base 
                      rounded-sm outline-none focus-within:border-farmlink_yellow 
                      focus-within:shadow-farmlinkInput duration-100"
                  type="password"
                />
                {errPassword && (
                  <p
                    className="text-red-600 text-xs font-semibold tracking-wide
                            flex items-center gap-2 -mt-1.5"
                  >
                    <span className="italic font-titleFont font-extrabold text-base">
                      !
                    </span>
                    {errPassword}
                  </p>
                )}
                {userPassErr && (
                  <p
                    className="text-red-600 text-xs font-semibold tracking-wide
                            flex items-center gap-2 -mt-1.5"
                  >
                    <span className="italic font-titleFont font-extrabold text-base">
                      !
                    </span>
                    {userPassErr}
                  </p>
                )}
              </div>
              <button
                onClick={handleLogin}
                className="w-full font-titleFont font-medium text-base bg-gradient-to-tr
                from-farmlink_yellow to-yellow-200 border hover:from-yellow-300 hover:to-yellow-200 
                border-yellow-500 hover:border-yellow-700 active:bg-gradient-to-bl
                active:from-yellow-400 active:to-yellow-500 duration-200 py-1.5 mt-4"
              >
                Continue
              </button>
              {
                    loading && (
                        <div className='flex justify-center'>
                            <ProgressBar
                            height="80"
                            width="80"
                            ariaLabel="progress-bar-loading"
                            wrapperStyle={{}}
                            wrapperClass="progress-bar-wrapper"
                            borderColor = '#ffa721'
                            barColor = '#017a3f'
                            />
                        </div>
                    )
                }
            </div>
            <p className="text-xs text-black leading-4 mt-4">
              By continuing, you agree to FarmLink's
              <span
                className="text-blue-600 hover:text-orange-700 
                  hover:underline underline-offset-1 cursor-pointer"
              >
                {" "}
                Conditions of Use{" "}
              </span>{" "}
              and{" "}
              <span
                className="text-blue-600 hover:text-orange-700 
                  hover:underline underline-offset-1 cursor-pointer"
              >
                Privacy Notice.
              </span>
            </p>
            <p className="text-xs text-gray-600 mt-4 cursor-pointer group">
              <ArrowRightIcon />
              <span className="text-blue-600 group-hover:text-orange-700 group-hover:underline underline-offset-1 ">
                Need help?
              </span>
            </p>
          </div>
          
            <p className="w-full text-xs text-gray-600 mt-4 flex items-center">
              <span className="w-1/3 h-[1px] bg-zinc-400 inline-flex"></span>
              <span className="w-1/3 text-center">New to FarmLink?</span>
              <span className="w-1/3 h-[1px] bg-zinc-400 inline-flex"></span>
            </p>
          
          <Link className="w-full" to="/registration">
            <button
              className="w-full py-1.5 mt-4 text-sm font-normal rounded-sm
                bg-gradient-to-t from-slate-200 to-slate-100 hover:bg-gradient-to-b border
                border-zinc-400 active:border-yellow-800 active:focus-within:border-farmlink_yellow"
            >
              Create your FarmLink account
            </button>
          </Link>
        </form>
        )}
      </div>
      <div
        className="w-full bg-gradient-to-t from-white via-white to-zinc-200 flex flex-col gap-4 justify-center
        items-center py-10"
      >
        <div className="flex items-center gap-6">
          <p
            className="text-xs text-blue-600 hover:text-orange-600 hover:underline
          underline-offset-1 cursor-pointer duration-100"
          >
            Conditions of Use
          </p>
          <p
            className="text-xs text-blue-600 hover:text-orange-600 hover:underline
          underline-offset-1 cursor-pointer duration-100"
          >
            Privacy Notice
          </p>
          <p
            className="text-xs text-blue-600 hover:text-orange-600 hover:underline
          underline-offset-1 cursor-pointer duration-100"
          >
            Help
          </p>
        </div>
        <div>
          <p className="text-xs text-gray-600">
            © 2023, FarmLink.com, Inc. or its affiliates
          </p>
        </div>
      </div>
    </div>
  );
};

export default Signin;
