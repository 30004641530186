import React, {useEffect, useState} from 'react'
import { useSelector } from 'react-redux';
import HeaderBottom from './HeaderBottom'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Dropdown from '../Dropdown';
import HeaderBottomTwo from './HeaderBottomTwo';
import {Link } from "react-router-dom";
import ProfDropdown from '../ProfDropdown';
import CommentIcon from '@mui/icons-material/Comment';
import { getAuth } from 'firebase/auth';
import { db } from '../../firebase.config';
import {profile} from "../../assets/index"

const Header = () => {
  const [profdropdown, setProfDropdown] = useState(false);
  
  const [dropdown,setDropdown]= useState(false);
  const userInfo = useSelector((state) => state.farmlinkReducer.userInfo)

  const auth = getAuth();
  const [userExists, setUserExists] = useState(false);
  const [posts, setPosts] = useState([]);

    useEffect(() => {
      if(auth.currentUser){
      const getPostsFromFirebase = [];
      const subscriber = db.collection('user').doc(auth.currentUser.uid)
        .onSnapshot((doc) => {
          setUserExists(!doc.empty)
            getPostsFromFirebase.push({
              ...doc.data(), //spread operator
              key: doc.id, // `id` given  by Firebase
            });
          
          setPosts(getPostsFromFirebase);
        });
        // return cleanup function
      return () => subscriber();
      }
      
    },[auth.currentUser]); // empty dependencies array => useEffect only called once
  
    

  return (
    <div className='w-full sticky top-0 z-50'>
    <div className="w-full h-[50px] bg-black text-white py-5 px-4">
    <div className='float-right font-bold flex flex-row gap-0 -mt-1.5'>
    {/* {userInfo && (<Link to='/chat'>
    <a className="relative mdl:-mt-[2px] -ml-[270px] mdl:-ml-[280px] hover:text-farmlink_green hover:cursor-pointer">
      <CommentIcon  sx={{ fontSize: 30 }}/></a>
      </Link>)} */}
    
    <a className='-ml-[220px] mdl:-ml-[240px] mdl:mt-0 mt-0 text-sm mdl:text-xl hover:cursor-pointer' 
    onMouseEnter={() => setDropdown(true)} 
    onMouseLeave={() => setDropdown(false)}
    >SUPPORT<span><KeyboardArrowDownIcon />
    </span>{dropdown && <Dropdown />}</a>
    {userExists ? (
        posts.map((post) => 
        <div className='flex flex-row -ml-[120px] absolute gap-2 border border-transparent cursor-pointer' 
            onMouseEnter={() => setProfDropdown(true)} 
            onMouseLeave={() => setProfDropdown(false)} >
          
          <img className='w-10 h-9 rounded-full -mt-1.5'
                    src={post.imagePath || "https://www.pngitem.com/pimgs/m/146-1468479_my-profile-icon-blank-profile-picture-circle-hd.png"}
                    alt='UserImg'
                    />
        
          <a className="text-xl font-bolder relative mt-0.5" 
            onMouseEnter={() => setProfDropdown(true)}>
              {post.fullName.split(" ", 1)}
              {profdropdown && <ProfDropdown/>}
          </a>
        </div>
        )) : (<div>
          <Link to="/signin">
        <a className="hover:text-farmlink_green relative text-sm mdl:text-xl ml-[30px]">SIGN IN</a>
    </Link>
    
    </div>
    )}
    </div>
    </div>
    <HeaderBottom />
    <HeaderBottomTwo />
    </div>
  )
}

export default Header