import React from 'react'
import { getAuth } from "firebase/auth";
import { db } from '../firebase.config';
import { useEffect, useState } from 'react'
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import { Link } from 'react-router-dom';
import { SellerProducts } from '../components/Home/SellerProducts';

export const Shop = () => {
  const [loading, setLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  const auth = getAuth();
  const notAvailable = () =>{
    alert("Download the AniDelivery mobile app to access this feature!")
  }
  

  //state of products
  const [sellerProducts, setSellerProducts] = useState([]);

  //getting product function

  const [orders, setOrders] = useState([])

  const getOrders = async () =>{
    
    if(auth.currentUser){
    const orders = await db.collection('user').doc(auth.currentUser.uid).collection('sellerOrders').where(("product.seller.userId") ,'==', auth.currentUser.uid).get();
    const ordersArray = [];
    for (var snap of orders.docs){
      var data = snap.data();
      data.uid = snap.id;
      ordersArray.push({
        ...data
        
      })

      if(ordersArray.length === orders.docs.length){
        setOrders(ordersArray);
      }
    }
    
  }
}
  useEffect(()=>{
    getOrders();
  },[auth.currentUser])
 

  async function getEntries() {
    if(auth.currentUser){
    const sellerProducts = await db.collection('Products').where(("seller.userId") ,'==', auth.currentUser.uid).get();
    const sellerproductsArray = [];
    for (var snap of sellerProducts.docs){
      var data = snap.data();
      data.uid = snap.id;
      sellerproductsArray.push({
        ...data
       
      })
      if(sellerproductsArray.length === sellerProducts.docs.length){
        setSellerProducts(sellerproductsArray);
      }
    }

  }

  }


  useEffect(()=>{
    getEntries();
  },[auth.currentUser])

  

  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if(auth.currentUser){
    const getPostsFromFirebase = [];
    const subscriber = db.collection('user').doc(auth.currentUser.uid).collection('sellerInformation')
      .onSnapshot((querySnapshot) => {
        setSellerExists(!querySnapshot.empty)
        querySnapshot.forEach((doc) => {
          getPostsFromFirebase.push({
            ...doc.data(), //spread operator
            key: doc.id, // `id` given  by Firebase
          });
        });
        setPosts(getPostsFromFirebase);
        setLoading(false);
      });
      // return cleanup function
    return () => subscriber();
    }

    
  },[auth.currentUser]); // empty dependencies array => useEffect only called once

  const [sellerExists, setSellerExists] = useState(false);


  return (
    <div>
      <div
    className='flex flex-row justify-center items-center text-xl font-bold pt-0'>
      <div className='w-full h-full mx-auto flex flex-col items-center bg-white'>
        <div className='w-full border border-black'></div>
  {posts.length > 0 ? (
        posts.map((post) => <div className='py-2 flex flex-col items-center'
        key={post.key}>
          <h1 className='text-l font-titleFont pb-2'>{post.farmerName} Farm</h1>
          <h2 className='text-sm text-gray-600 font-semibold'>{post.shopLocation}</h2>
          </div>)
      ) : (
        <h1></h1>
      )}
      <div className='w-full border border-black'></div>
      <div className='items-center justify-center gap-4 flex lgl:flex-row flex-col mt-5 mb-2'>
        <Link to="/addproducts">
      <button className="w-[270px] h-[40px] mdl:w-[270px] mdl:h-[130px] text-farmlink_green bg-white rounded-md py-1 px-2
        font-semibold cursor-pointer hover:bg-gray-200 border-[2px] border-farmlink_green">
          <span className='text-farmlink_green float-left'>
            <AddBusinessIcon sx={{ fontSize: 30 }}/>
          </span>
          {" "}Post a Product<br></br>
        </button>
        </Link>

        <button onClick={notAvailable} className="w-[270px] h-[40px] mdl:w-[270px] mdl:h-[130px] text-farmlink_green bg-gray-400 rounded-md py-1 px-2
        font-semibold cursor-pointer hover:bg-gray-200 border-[2px] border-farmlink_green">
          <span className='text-farmlink_green float-left'>
            <AnalyticsIcon sx={{ fontSize: 30 }}/>
          </span>
          {" "}Sales Summary<br></br>
        </button>
        <button onClick={notAvailable} className="w-[270px] h-[40px] mdl:w-[270px] mdl:h-[130px] text-farmlink_green bg-gray-400 rounded-md py-1 px-2
        font-semibold cursor-pointer hover:bg-gray-200 border-[2px] border-farmlink_green">
          <span className='text-farmlink_green float-left'>
            <ThumbUpIcon sx={{ fontSize: 30 }}/>
          </span>
          {" "}Shop Ratings<br></br>
        </button>
        </div>
         <div className='mb-10'>
   
    <Box sx={{ maxWidth: { xs: 350, sm: 1000 }, "& .MuiTab-root.Mui-selected": { color: "#017a3f" }, "& .MuiTabs-indicator": { bgcolor: "#017a3f" } }}>
    <TabContext value={value} >
    <Box sx={{ borderBottom: 1, borderColor: '#017a3f' }}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        <Tab label="Products" value="1" />
        <Tab label="Ordered" value="2" />
        <Tab label="To Deliver" value="3" />
        <Tab label="To Be Picked Up" value="4" />
        <Tab label="Ready for Meet up" value="5" />
        <Tab label="Cancelled Orders" value="6" />
        <Tab label="Completed Orders" value="7" />
      </Tabs>
    </Box>
    <TabPanel value="1" className='bg-gray-200 h-[500px] overflow-auto'>
          
          <div className='flex flex-col items-center justify-center'>
          
        <br></br>
        {sellerProducts.length > 0 && (
          <div className='container-fluid'>
            <div id='' className=''>
              <SellerProducts sellerProducts={sellerProducts}/>
            </div>

          </div>
        )}
        {sellerProducts.length < 1 && (
          <div className='container-fluid font-bold'>No Products Posted!</div>
        )
        }
      </div>
      </TabPanel>
      <TabPanel value="2" className='bg-gray-200 h-[500px] overflow-auto'>

      < div
    className='flex flex-row justify-center items-center text-xl font-bold pt-0'>
        <div>
        <div className='flex flex-col items-center justify-center'>
          
          <br></br>
          {orders.length > 0 && (
            orders.map((order) => 
            <div id='sellerproduct' className='items-center justify-center gap-6 xl:gap-6 px-4 pb-2'>
            <div className='bg-white -mt-6 mdl:w-[700px] w-[300px] mdl:h-[full] h-[full]
            border-[1px] border-gray-200 py-8 relative flex flex-col mdl:flex-row gap-2 mb-7'
        key={order.key}>

          <span className='text-xs capitalize italic absolute top-2 right-2 text-gray-500'>
            {order.product.productType}</span>

            <div className='items-center'>
            <img className='w-[150px] mdl:mt-6 h-[150px] mdl:ml-10 ml-[75px]'src={order.product.images} alt="product-img"/>
        </div>
        <div className='ml-[25px] -mb-4 w-[250px] mdl:w-[400px]'>
        <p className='font-bold mt-6'>{order.product.productName}</p>
        <p className='font-bold text-xs -mt-0'>{order.product.productVariety}</p>


        <p className='text-base'>Per Kilogram</p>
        <p className='text-base'>Buyer: <span className='font-light'>{order.buyerId}</span></p>
        {/* <p className='text-base'>Address: <span className='font-light'>{order.address.street} 
        {order.address.barangay}, {order.address.city} {order.address.postalCode}, {order.address.province}</span></p> */}
        
        <p className='text-farmlink_yellow font-bold'>Order Total: ₱{" "}{order.totalPrice}</p>
        <p className='font-light text-xl mdl:text-lg'>Ordered Quantity: {order.buyQuantity}{" "}kg</p>
        </div>
        

              </div>
              </div>

          ))}
          {orders.length < 1 && (
            <div className='container-fluid font-bold'>No Orders Yet!</div>
          )
          }
        </div>
        </div>
        </div>
      </TabPanel>
      <TabPanel value="3" className='bg-gray-200 h-[300px] overflow-auto'>
          
      <div className='flex flex-col items-center justify-center'>
          
      In Progress
        </div>
      </TabPanel>
      <TabPanel value="4" className='bg-gray-200 h-[300px] overflow-auto'>
          
      <div className='flex flex-col items-center justify-center'>
          
      In Progress
        </div>
      </TabPanel>
      <TabPanel value="5" className='bg-gray-200 h-[300px] overflow-auto'>
          
          <div className='flex flex-col items-center justify-center'>
          
          In Progress
      </div>
      </TabPanel>
      <TabPanel value="6" className='bg-gray-200 h-[300px] overflow-auto'>
          
      <div className='flex flex-col items-center justify-center'>
          
      In Progress
        </div>
      </TabPanel>
      <TabPanel value="7" className='bg-gray-200 h-[300px] overflow-auto'>
          
      <div className='flex flex-col items-center justify-center'>
          
      In Progress
        </div>
      </TabPanel>
        </TabContext>
      </Box>
      
    </div>
      </div>
  </div>

    </div>
  )
}