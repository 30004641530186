import React, { useEffect, useState } from 'react'
import Banner from "../components/Home/Banner";
import { auth, db } from '../firebase.config';
import { Products } from '../components/Home/Products';
import '../components/Home/Home.css'
import { getAuth } from 'firebase/auth';
import { useSelector } from 'react-redux';

export const Home = () => {

  const userInfo = useSelector((state) => state.farmlinkReducer.userInfo)

  const auth = getAuth();

  //state of products
  const [products, setProducts] = useState([]);
  const [productsGuest, setProductsGuest] = useState([]);

  //getting product function

  const getProducts = async () =>{
    if(auth.currentUser){
    const products = await db.collection('Products').where("seller.userId" ,"!=", auth.currentUser.uid).where("status" ,"==", "Approved").get();
    const productsArray = [];
    for (var snap of products.docs){
      var data = snap.data();
      data.uid = snap.id;
      productsArray.push({
        ...data
      })
      if(productsArray.length === products.docs.length){
        setProducts(productsArray);
      }
    }
  }
  }

  useEffect(()=>{
    getProducts();
  },[auth.currentUser])


 const getProductsGuest = async () =>{
  const products = await db.collection('Products').where(("status") ,"==", "Approved").get();
  const productsArray = [];
  for (var snap of products.docs){
    var data = snap.data();
    data.uid = snap.id;
    productsArray.push({
      ...data
    })
    if(productsArray.length === products.docs.length){
      setProductsGuest(productsArray);
    }
  }
}

useEffect(()=>{
  getProductsGuest();
},[auth.currentUser])


  return (
    <>
    <div>
      <Banner />
      <div className='w-full h-full mdl:mb-0 mb-[300px]'>
        <br></br>
        {auth.currentUser && products.length > 0 ? (
          <div className='container-fluid'>
            <div id='productsform' className='-mt-[150px] mdl:h-full h-[950px] flex flex-wrap mdl:mb-10 -mb-[300px]'>
              <Products products={products}/>
            </div>

          </div>
        ):(<div></div>)}

        {auth.currentUser == null && (
          <div className='container-fluid'>
          <h1 className='text-center'></h1>
          <div id='productsform' className='-mt-[150px] mdl:h-full h-[950px] flex flex-wrap mdl:mb-10 -mb-[300px]'>
            <Products products={productsGuest}/>
          </div>

        </div>
        )}

      </div>
        
    </div>
    </>
  )
}