import React, { useState, useEffect } from 'react';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import { Link } from 'react-router-dom';
import { db } from '../firebase.config';
import { getAuth } from "firebase/auth";
import StoreIcon from '@mui/icons-material/Store';
import { useSelector } from 'react-redux';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import { useNavigate } from 'react-router-dom';
import EditNoteIcon from '@mui/icons-material/EditNote';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import HistoryIcon from '@mui/icons-material/History';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';

const Profile = () => {

  const navigate = useNavigate();
  const auth = getAuth(); 

  const [registeredFarmer, setRegisteredFarmer] = useState([]);
  const userInfo = useSelector((state) => state.farmlinkReducer.userInfo);

  const notAvailable = () =>{
    alert("Download the AniDelivery mobile app to access this feature!")
  }

  const [userExists, setUserExists] = useState(false);
  const [posts, setPosts] = useState([]);

    useEffect(() => {
      if(auth.currentUser){
      const getPostsFromFirebase = [];
      const subscriber = db.collection('user').doc(auth.currentUser.uid)
        .onSnapshot((doc) => {
          setUserExists(!doc.empty)
            getPostsFromFirebase.push({
              ...doc.data(), //spread operator
              key: doc.id, // `id` given  by Firebase
            });
          
          setPosts(getPostsFromFirebase);
        });
        // return cleanup function
      return () => subscriber();
      }
  
      
    },[auth.currentUser]); // empty dependencies array => useEffect only called once
  



  useEffect(() => {
    if(auth.currentUser){
    const unsubscribe = db.collection('user').doc(auth.currentUser.uid).collection('sellerInformation').where("status" , "==" , "Approved")
    .onSnapshot((doc) => {
      setRegisteredFarmer(!doc.empty);
      

    });
    return unsubscribe;
  }
  }, [auth.currentUser]);




  return (
    <div>
      {userExists ? (

    posts.map((post) => 

      <div className='w-full bg-white flex flex-col items-center justify-center'>
      <div className='flex flex-row mdl:gap-[400px]'>
      <h1 className='text-xl font-bold ml-3'>Profile</h1>

      <button onClick={notAvailable} className='hover:cursor-pointer hover:bg-gray-200'><EditNoteIcon sx={{ fontSize: 30 }} /></button>

      </div>
      <div className='mdl:w-[500px] w-full border border-black'></div>
      <div className='gap-0 py-2'>
        
      <img className='w-[100px] h-[100px] mdl:w-[150px] mdl:h-[150px] rounded-full'
                    src={post.imagePath || "https://www.pngitem.com/pimgs/m/146-1468479_my-profile-icon-blank-profile-picture-circle-hd.png"}
                    alt='UserImg'
                    />
      </div>
      <div className='items-center text-center flex flex-col'>
      <a className='text-xl font-bold'>{post.fullName}</a>
   
      {registeredFarmer ? (
      <a className='text-sm'>Buyer/Seller</a>
      ) : (<a className='text-sm'>Buyer</a>)}
      
      <a className='text-sm text-gray-400'><span><EmailIcon /></span>{" "}{post.email}</a>
      </div>
      <div className='mdl:w-[500px] w-full border border-black'></div>
      <div className='items-center justify-center gap-4 grid grid-cols-3 lgl:grid-cols-3 py-2'>

      <Link to ="/myorders">
        <button className="w-[100px] h-[100px] mdl:w-[270px] mdl:h-[130px] text-farmlink_green bg-white rounded-md py-1 px-2
        font-semibold cursor-pointer hover:bg-gray-200 border border-farmlink_green">
          <span className='text-black'>
            <LocalOfferIcon sx={{ fontSize: 40 }}/>
          </span>
          {" "}<br></br>
          <span className='text-farmlink_green font-bold mdl:text-xl text-[10px]'>Order</span>
        </button>
        </Link>
        <Link to ="/useraddress">
        <button className="w-[100px] h-[100px]  mdl:w-[270px] mdl:h-[130px] text-farmlink_green bg-white rounded-md py-1 px-2
        font-semibold cursor-pointer hover:bg-gray-200 border border-farmlink_green">
          <span className='text-black'>
            <LocationOnIcon sx={{ fontSize: 40 }}/>
          </span>
          {" "}<br></br>
          <span className='text-farmlink_green font-bold mdl:text-xl text-[10px]'>Add Shipping Address</span>
        </button>
        </Link>

        {registeredFarmer ? (
          <Link to ="/shop">
          <button className="w-[100px] h-[100px]  mdl:w-[270px] mdl:h-[130px] text-farmlink_green bg-white rounded-md py-1 px-2
          font-semibold cursor-pointer hover:bg-gray-200 border border-farmlink_green">
            <span className='text-black'>
              <StoreIcon sx={{ fontSize: 40 }}/>
            </span>
            {" "}<br></br>
            <span className='text-farmlink_green font-bold mdl:text-xl text-[10px]'>Seller Profile</span>
          </button>
          </Link>
        ) : (
          <Link to="/farmerregistration">
            <button className="w-[100px] h-[100px] mdl:w-[270px] mdl:h-[130px] text-farmlink_green bg-white rounded-md py-1 px-2
            font-semibold cursor-pointer hover:bg-gray-200 border border-farmlink_green">
              <span className='text-black'>
                <AgricultureIcon sx={{ fontSize: 40 }}/>
              </span>
              {" "}<br></br>
              <span className='text-farmlink_green font-bold mdl:text-xl text-[10px]'>Register as a Seller </span>
            </button>
          </Link>
        )}
        </div>
        
        <div className='items-center justify-center gap-4 grid grid-cols-3 lgl:grid-cols-3 py-2 mb-0'>
          

        <button onClick={notAvailable} className="w-[100px] h-[100px] mdl:w-[270px] mdl:h-[130px] text-farmlink_green bg-gray-400 rounded-md py-1 px-2
        font-semibold cursor-pointer hover:bg-gray-200 border border-farmlink_green">
          <span className='text-black'>
            <PeopleOutlineIcon sx={{ fontSize: 40 }}/>
          </span>
          {" "} <br></br>
          <span className='text-farmlink_green font-bold mdl:text-xl text-[10px]'>Forum</span>
        </button>

        <button onClick={notAvailable} className="w-[100px] h-[100px] mdl:w-[270px] mdl:h-[130px] text-farmlink_green bg-gray-400 rounded-md py-1 px-2
        font-semibold cursor-pointer hover:bg-gray-200 border border-farmlink_green">
          <span  className='text-black'>
            <NotificationsActiveIcon sx={{ fontSize: 40 }}/>
          </span>
          {" "}<br></br>
          <span className='text-farmlink_green font-bold mdl:text-xl text-[10px]'>Alert</span>
        </button>

        <button onClick={notAvailable} className="w-[100px] h-[100px] mdl:w-[270px] mdl:h-[130px] text-farmlink_green bg-gray-400 rounded-md py-1 px-2
        font-semibold cursor-pointer hover:bg-gray-200 border border-farmlink_green">
          <span className='text-black'>
            <LocalShippingIcon sx={{ fontSize: 40 }}/>
          </span>
          {" "}<br></br>
          <span className='text-farmlink_green font-bold mdl:text-xl text-[10px]'>Track Order</span>
        </button>

</div>
        <div className='items-center justify-center gap-4 grid grid-cols-2 lgl:grid-cols-2 py-2 mb-12'>


        <button onClick={notAvailable} className="w-[100px] h-[100px]  mdl:w-[270px] mdl:h-[130px] text-farmlink_green bg-gray-400 rounded-md py-1 px-2
        font-semibold cursor-pointer hover:bg-gray-200 border border-farmlink_green">
          <span className='text-black'>
            <HistoryIcon sx={{ fontSize: 40 }}/>
          </span>
          {" "}<br></br>
          <span className='text-farmlink_green font-bold mdl:text-xl text-[10px]'>Transaction History</span>
        </button>

        <button onClick={notAvailable} className="w-[100px] h-[100px]  mdl:w-[270px] mdl:h-[130px] text-farmlink_green bg-gray-400 rounded-md py-1 px-2
        font-semibold cursor-pointer hover:bg-gray-200 border border-farmlink_green">
          <span className='text-black'>
            <ThumbUpOffAltIcon sx={{ fontSize: 40 }}/>
          </span>
          {" "}<br></br>
          <span className='text-farmlink_green font-bold mdl:text-xl text-[10px]'>Feedback</span>
        </button>

        
      </div>
      
    </div>
    )):(
      <div className='w-full h-[100px] flex items-center justify-center'>
      <p className='font-bold'>Create an Account before accessing this page</p>
      </div>
)}
    </div>
    
  )
}

export default Profile