import React from 'react'
import { Link } from 'react-router-dom'

const NotAvailable = () => {
  return (
    <div className="w-full h-[600px] bg-gray-300 py-6">
      <div className="w-full py-8">
        <div className="w-64 mx-auto text-center mt-[150px] flex flex-col gap-1">
          <p className="text-xl font-bold">Download the AniDelivery mobile app to access this feature!</p>
          <Link to="/">
          
          <button className="w-[100px] bg-[#ffa721] rounded-md py-1 font-semibold cursor-pointer hover:bg-[#d98d1c]">
            Go back
          </button>
          </Link>
        </div>
      </div>
        </div>

  )
}

export default NotAvailable


