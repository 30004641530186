import React from 'react'
import { middleList } from '../../constants';
import FooterMiddleList from './FooterMiddleList';
import {logo3, phFlag, qr } from "../../assets/index";

const FooterMiddle = () => {
  return (
    <div className="w-full bg-farmlink_green text-white">
      {/* ============ Top Start here ================== */}
      <div className="w-full border-b-[1px] border-gray-500 p-10">
        <div className="max-w-5xl mx-auto text-gray-300">
          <div className="w-full grid grid-cols-2 md:grid-cols-2 lgl:grid-cols-4 gap-8 md:place-items-center md:items-start">
            {middleList.map((item) => (
              <FooterMiddleList
                key={item._id}
                title={item.title}
                listItem={item.listItem}
              />
            ))}
            <div className=''><h2 className='font-bold text-white pb-2'>ANIDELIVERY APP DOWNLOAD</h2>
            <img className='w-[150px] h-[150px] mdl:w-[200px] mdl:h-full' src={qr}></img></div>
          </div>
        </div>
      </div>
      {/* ============ Top End here ==================== */}
      {/* ============ Bottom Start here =============== */}
      <div className="w-full flex gap-6 items-center justify-center py-6">
        <div>
          <img className="w-60 pt-3" src={logo3} alt="logo" />
        </div>
        <div className="flex gap-1 items-center justify-center border border-gray-500 hover:border-amazon_yellow cursor-pointer duration-200 px-2 py-1">
          <img className="w-6" src={phFlag} alt="flagImg" />
          <p>Philippines</p>
        </div>
      </div>
      {/* ============ Bottom End here ================= */}
    </div>
  );
}

export default FooterMiddle