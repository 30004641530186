import { createSlice } from "@reduxjs/toolkit";

const initialState={
    products: [],
    userInfo: null
};

export const farmlinkSlice = createSlice({
    name:"farmlink",
    initialState,
    reducers:{
        addToCart:(state,action)=>{
            const item = state.products.find((item)=>item.id === action.payload.id)
            if(item){
                item.quantity += action.payload.quantity;
            }else(
            state.products.push(action.payload)
            )
    },
    incrementQuantity:(state,action)=>{
        const item =  state.products.find((item)=> item.id === action.payload)
        item.quantity++
    },
    decrementQuantity:(state,action)=>{
        const item = state.products.find((item)=> item.id === action.payload)
        if(item.quantity ===1){
            item.quantity = 1
        }else{
            item.quantity--
        }
    },
    removeItem:(state,action)=>{
        state.products = state.products.filter((item)=>item.id!== action.payload
        );
    },
    resetCart:(state)=>{
        state.products=[];
    },
    setUserInfo:(state,action)=>{
        state.userInfo = action.payload
    },
    userSignOut:(state)=>{
        state.userInfo = null;
        state.products = []
    }/* ,
    userSignIn:(state)=>{
        state.products = [];
    } */
    /* userSignout:(state)=>{
        state.products = []
    } */
},});

export const{addToCart,removeItem,resetCart,incrementQuantity,decrementQuantity, setUserInfo, userSignOut, userSignIn} = farmlinkSlice.actions
export default farmlinkSlice.reducer;