import React from 'react'
import { Link } from 'react-router-dom'

const PgFOF = () => {
  
    return (
        <div className="w-full bg-gray-300 py-6">
      <div className="w-full py-8">
        <div className="w-64 mx-auto text-center flex flex-col gap-1">
          <p className="text-xl font-bold">Products temporarily unavailable</p>
          <Link to="/">
          
          <button className="w-[100px] bg-[#ffa721] rounded-md py-1 font-semibold cursor-pointer hover:bg-[#d98d1c]">
            Go back
          </button>
          </Link>
        </div>
      </div>
        </div>
    )
}

export default PgFOF

