import React from 'react';
import { Link } from 'react-router-dom';
import "./Dropdown.css";

function Dropdown() {
  return <>
  <ul className='support-submenu top-[40px] mdl:-ml-[15px] -ml-[30px]'>
    <Link to='/aboutus'>
    <li className='aboutus'>About Us</li>
    </Link>
    <Link to='/help'>
    <li className='help'>Help</li>
    </Link>
  </ul>
  
  </>
}

export default Dropdown