import React from 'react'
import { SellerIndividualProducts } from './SellerIndividualProducts'

export const SellerProducts = ({sellerProducts}) => {

  /* console.log(products); */

   return sellerProducts.map((sellerIndividualProducts)=>(
    <div className=''> 
    <SellerIndividualProducts key={sellerIndividualProducts.uid} sellerIndividualProducts={sellerIndividualProducts}/>
    </div> 
  ))
}