import React, { useState, useEffect } from 'react'
import { getAuth } from 'firebase/auth';
import { db } from '../firebase.config';

export const MyOrders = () => {

  const auth = getAuth();
  const [orders, setOrders] = useState([])

  const getOrders = async () =>{
    
    if(auth.currentUser){
    const orders = await db.collection('user').doc(auth.currentUser.uid)
    .collection('orders').where(("buyerId") ,'==', auth.currentUser.uid).get();
    const ordersArray = [];
    for (var snap of orders.docs){
      var data = snap.data();
      data.uid = snap.id;
      ordersArray.push({
        ...data
        
      })

      if(ordersArray.length === orders.docs.length){
        setOrders(ordersArray);
      }
    }
    
  }
}

  useEffect(()=>{
    getOrders();
  },[auth.currentUser])
  

  return (
    <div
    className='flex flex-row justify-center items-center text-center text-xl font-bold pt-6 pb-6'>
      
      <div className='mdl:w-[800px] w-full h-[600px] mx-auto flex flex-col items-center bg-white overflow-y-scroll'>
      <h2> My Orders </h2>

        <div>
        <div className='flex flex-col items-center justify-center'>
          
          <br></br>
          {orders.length > 0 && (
            orders.map((order) => 
            <div id='sellerproduct' className='items-center justify-center gap-6 xl:gap-6 px-4 pb-2'>
            <div className='bg-white -mt-6 mdl:w-[700px] w-[300px] mdl:h-[full] h-[full]
            border-[1px] border-gray-200 py-8 relative flex flex-row gap-2 mb-7'
        key={order.key}>

          <span className='text-xs capitalize italic absolute top-2 right-2 text-gray-500'>
            {order.product.productType}</span>

            <div className='mdl:mt-10 mt-[100px]'>
            <img className='w-[150px] h-[150px] ml-5'src={order.product.images} alt="product-img"/>
        </div>
        <div className='ml-[25px] -mb-4 w-[400px]'>
        <p className='font-bold mt-6'>{order.product.name}</p>
        <p className='font-bold text-xs -mt-0'>{order.product.productVariety}</p>
        <p className='text-base'>Per Kilogram</p>
        <p className='text-farmlink_yellow font-bold'>Order Total: ₱{" "}{order.totalPrice}</p>
        <p className='font-bold text-xl mdl:text-lg'>Ordered Quantity: {order.buyQuantity}{" "}kg</p>
        <p className='text-sm'>Mode of Payment: {order.orderType}</p>
        <p className='font-bold text-sm'>Order Date: <span className='text-red-500'>{order.date}</span></p>
        <p className='font-bold text-sm'>Status: <span className='text-farmlink_green font-bold text-xl'>{order.orderStatus}</span></p>
        <p className='font-bold text-sm'>Shipping Address Selected: {order.address.street} {order.address.barangay}, {order.address.city} {order.address.postalCode},  {order.address.province}</p>
              </div>
                </div>
                  </div>

          ))}
          {orders.length < 1 && (
            <div className='container-fluid font-bold'>No Orders Yet!</div>
          )
          }
        </div>
        </div>
        </div>
        </div>
  )
}
