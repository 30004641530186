import React from 'react'
import { IndividualProduct } from './IndividualProduct'



export const Products = ({products}) => {

  /* console.log(products); */
  /* const dispatch = useDispatch(); */

   return products.map((individualProduct)=>(
    
    <div> 
    
    {/* <Link to={`/product-details/${individualProduct.uid}`}> */}
    <IndividualProduct key={individualProduct.uid} individualProduct={individualProduct}/>
   {/*  </Link> */}
    </div> 
  ))
}