import React, { useState, useEffect } from 'react';
import {storage, db} from '../firebase.config';
import { useNavigate } from 'react-router-dom';
import { ProgressBar } from "react-loader-spinner";
import { getAuth } from 'firebase/auth';
import { useSelector } from 'react-redux';

import { motion } from 'framer-motion';


   export const AddAddress = (e) => {

  const [userExists, setUserExists] = useState(false);

    useEffect(() => {
      if(auth.currentUser){

      const subscriber = db.collection('user').doc(auth.currentUser.uid)
        .onSnapshot((doc) => {
          setUserExists(!doc.empty)
        });
        // return cleanup function
      return () => subscriber();
      }
  
      
    },[]); // empty dependencies array => useEffect only called once

    const userInfo = useSelector((state) => state.farmlinkReducer.userInfo)
    const [selectedPostalCode, setSelectedPostalCode] = useState('');
    const [name, setName] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [addressType, setAddressType] = useState('');
    const [selectedProvince, setSelectedProvince] = useState('');
    const [selectedMunicipality, setSelectedMunicipality] = useState('');
    const [barangay, setBarangay] = useState('');
    const [street, setStreet] = useState('');

    var count = 0;
    for (const key in mobileNumber) {

     count++;
 
    }

    //loading
    const [loading, setLoading] = useState(false);
    const [successMsg, setSuccessMsg] = useState("");
    
    const auth = getAuth();
    const navigate =useNavigate();

    const handleGNumChange = e => {
        const limit = 13;
        setMobileNumber(e.target.value.slice(0, limit));
      
      };

    const addAddress = (e) => {
        e.preventDefault();

        if(auth.currentUser){

            db.collection('user').doc(auth.currentUser.uid).collection('shippingAddress').doc().set({
                            fullNameShipping:name,
                            phone: mobileNumber,
                            barangay,
                            street,
                            province: selectedProvince,
                            city: selectedMunicipality,
                            postalCode: selectedPostalCode,
                            addressType: addressType,
                            userId: auth.currentUser.uid
                          })
                          .then(() => {
                            setLoading(true)
                            setSuccessMsg("Address Successfully Added!");
                            console.log("Added");
                        })
                        .catch(error => {
                          console.log(error);
                      })
                      setLoading(true)
                        setTimeout(() => {
                            navigate("/useraddress")
                        }, 2000);
                      }
    }

        
        return (
          <div>
      {userExists ? (
            <div className='w-full'>
                <div className='mdl:w-full w-[345px] bg-white-100 pb-10 pt-5'>
          
          <form autoComplete="off" className='mdl:w-[500px] w-full mx-auto flex flex-col items-center mt-2'
           onSubmit={addAddress}>
          <div className='w-full rounded-tr-md rounded-br-md rounded-tl-md rounded-bl-md p-6 bg-white'>
            <h1 className='font-bold text-xl mb-5'>Add a Shipping Address</h1>
          
          <p className='text-sm font-bold'>Full Name</p>
          <input type='text' className='w-full py-1 border border-zinc-400 px-2 text-base 
                    rounded-sm outline-none focus-within:border-farmlink_yellow 
                    focus-within:shadow-farmlinkInput duration-100' 
                    placeholder='Enter Full Name' required 
                    onChange={(e) => setName(e.target.value)}></input>

          <p className='text-sm font-bold'>Address Type</p>
          <input type='text' className='w-full py-1 border border-zinc-400 px-2 text-base 
                    rounded-sm outline-none focus-within:border-farmlink_yellow 
                    focus-within:shadow-farmlinkInput duration-100' 
                    placeholder='Enter Address Type (Ex. Home, Work)' required 
                    onChange={(e) => setAddressType(e.target.value)}></input>
          <p className='text-sm font-bold'>Phone Number</p>
          <input className='w-full py-1 border border-zinc-400 px-2 text-base 
                    rounded-sm outline-none focus-within:border-farmlink_yellow 
                    focus-within:shadow-farmlinkInput duration-100' 
                    placeholder='Add Phone Number (Starting +63)' pattern='[\+]\d{12}' 
          id='mobilenumber' type='text' required value={mobileNumber} 
                    onChange={handleGNumChange} minLength={13} maxLength={13}></input>
                    <span className='text-sm flex flex-row text-gray-500 mdl:ml-[420px] ml-[265px] mt-1 -mb-12'>{count}/13</span>
<br/>
<br/>
          <p className='text-sm font-bold'>Province</p>

          <select className='w-full py-1 border border-zinc-400 px-2 text-base 
                    rounded-sm outline-none focus-within:border-farmlink_yellow 
                    focus-within:shadow-farmlinkInput duration-100' required onChange={(e) => 
                    setSelectedProvince(e.target.value)}>
                      <option value=""disabled selected hidden>Enter Province</option>
                    <option value="Pampanga">Pampanga</option>
                  </select>
          
                  <p className='text-sm font-bold'>City</p>
          <select className='w-full py-1 border border-zinc-400 px-2 text-base 
                    rounded-sm outline-none focus-within:border-farmlink_yellow 
                    focus-within:shadow-farmlinkInput duration-100' required onChange={(e) => 
                    (setSelectedMunicipality(e.target.value))}>
                    <option value="" disabled selected hidden>Enter City/Municipality</option>
                    <option value="Angeles City">Angeles City</option>
                    <option value="Mabalacat">Mabalacat</option>
                    <option value="Magalang">Magalang</option>
                    <option value="Floridablanca">Floridablanca</option>
                    <option value="Guagua">Guagua</option>
                    <option value="Lubao">Lubao</option>
                    <option value="Porac">Porac</option>
                    <option value="Santa Rita">Santa Rita</option>
                    <option value="Sasmuan">Sasmuan</option>
                    <option value="Arayat">Arayat</option>
                    <option value="Bacolor">Bacolor</option>
                    <option value="Mexico">Mexico</option>
                    <option value="San Fernando">San Fernando</option>
                    <option value="Santa Ana">Santa Ana</option>
                    <option value="Apalit">Apalit</option>
                    <option value="Candaba">Candaba</option>
                    <option value="Macabebe">Macabebe</option>
                    <option value="Masantol">Masantol</option>
                    <option value="Minalin">Minalin</option>
                    <option value="San Luis">San Luis</option>
                    <option value="San Simon">San Simon</option>
                    <option value="Santo Tomas">Santo Tomas</option>
                  </select>

          <br/>
          <p className='text-sm font-bold'>Barangay</p>
          <input type='text' className='w-full py-1 border border-zinc-400 px-2 text-base 
                    rounded-sm outline-none focus-within:border-farmlink_yellow 
                    focus-within:shadow-farmlinkInput duration-100' 
                    placeholder='Enter Barangay (Ex. San Vicente)' required 
                    onChange={(e) => setBarangay(e.target.value)}></input>

          <p className='text-sm font-bold'>Street</p>
          <input type='text' className='w-full py-1 border border-zinc-400 px-2 text-base 
                    rounded-sm outline-none focus-within:border-farmlink_yellow 
                    focus-within:shadow-farmlinkInput duration-100' 
                    placeholder='Enter Street (Ex. Purok 1)' required 
                    onChange={(e) => setStreet(e.target.value)}></input>

          <p className='text-sm font-bold'>Postal Code</p>
          <input id="myText" type="text" className='w-full py-1 border border-zinc-400 px-2 text-base 
                    rounded-sm outline-none focus-within:border-farmlink_yellow 
                    focus-within:shadow-farmlinkInput duration-100' 
                    placeholder='Enter Postal Code (Ex. 2001)' required 
                    onChange={(e) => setSelectedPostalCode(e.target.value)}></input>

                {
                    successMsg && (
                        <div className='mt-14'>
                            <motion.p 
                            initial={{ y: 10, opacity: 0}}
                            animate={{ y:0, opacity: 1}}
                            transition={{duration: 0.5}}
                            className='text-base font-titleFont font-semibold text-green-500 border-[1px]
                            border-green-500 px-2 text-center'
                            >{successMsg}</motion.p>
                        </div>
                    )
                }
                   { loading && (
                        <div className='flex justify-center'>
                            <ProgressBar
                            height="80"
                            width="80"
                            ariaLabel="progress-bar-loading"
                            wrapperStyle={{}}
                            wrapperClass="progress-bar-wrapper"
                            borderColor = '#ffa721'
                            barColor = '#017a3f'
                            />
                        </div>
                    )
                   }
          <div>
                        <button className="mdl:w-[400px] h-[40px] w-[250px] bg-[#ffa721] rounded-md 
                          py-2 mt-4 font-semibold cursor-pointer hover:bg-[#d98d1c] ml-[25px]" 
                          type='submit' >
                              Add Address
                          </button>
                          
                          </div>
          </div>
          </form>
          </div>
                
                
                          
                           </div>
                    /* </div> */
                    ):(
                      <div className='w-full h-[100px] flex items-center justify-center'>
                      <p className='font-bold'>Create an Account before accessing this page</p>
                      </div>
                    )}
                    </div>
                    
             

          )
    
                    }
  
