// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import { getDatabase } from "firebase/database";
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
/* import {
  getFirestore, collection , getDocs
} from 'firebase/firestore' */
/* import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage"; */

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
//justine database
/* const firebaseConfig = {
  apiKey: "AIzaSyDWMSWjIy6lKn9heLPsdSE2-B6sPZwAd4o",
  authDomain: "farmlinkdatabase.firebaseapp.com",
  databaseURL: "https://farmlinkdatabase-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "farmlinkdatabase",
  storageBucket: "farmlinkdatabase.appspot.com",
  messagingSenderId: "1058929793865",
  appId: "1:1058929793865:web:dd9d382ba53341510b9898",
  measurementId: "G-ZWZ5NCKDXP"
}; */

//arnel database application
const firebaseConfig = {
  apiKey: "AIzaSyAcJmxUCSWYAApK4yquj7f4ckFNFZCCo0U",
  authDomain: "farmlink-app-b781b.firebaseapp.com",
  databaseURL: "https://farmlink-app-b781b-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "farmlink-app-b781b",
  storageBucket: "farmlink-app-b781b.appspot.com",
  messagingSenderId: "425904298349",
  appId: "1:425904298349:web:6d8697ceb4216247c16264",
  measurementId: "G-ER3133EW0X"
};



// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const realtdb = getDatabase();


/* export const auth = getAuth(app);
export const storage = getStorage(app);
export const db = getFirestore(app); */
export { auth, db, storage, realtdb }
