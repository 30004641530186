import React from 'react';
import { useEffect, useState } from 'react';
import { Products } from './Home/Products';
import { db } from '../firebase.config';
import { getAuth } from 'firebase/auth';

const FruitsProduct = () => {

  const auth = getAuth();
  const [fruitsProducts, setFruitsProducts] = useState([]);

  const getFruits = async () =>{
  
    const fruitsProducts = await db.collection('Products').where("productType" ,'==', "Fruits").get();
    const fruitsArray = [];
    for (var snap of fruitsProducts.docs){
      var data = snap.data();
      data.uid = snap.id;
      fruitsArray.push({
        ...data
      })
      if(fruitsArray.length === fruitsProducts.docs.length){
        setFruitsProducts(fruitsArray);
      }
    }
}
  setInterval(getFruits, 1000);

  useEffect(()=>{
    getFruits();
  },[auth.currentUser]) 

  return (
    <div className='w-full bg-gray-100'>
    <h1 className='w-full text-[30px] items-center font-titleFont mx-auto gap-1 text-center mt-4'>FRUITS</h1>
    <div className='-mt-4 flex flex-col mdl:ml-[200px]'>
          {/* <button className=''><span><RefreshIcon /></span></button> */}
        <br></br>
        {fruitsProducts.length > 0 && (
          <div className='container-fluid'>
            <h1 className='text-center'></h1>
            <div id='productsform' className=''>
              <Products products={fruitsProducts}/>
            </div>

          </div>
        )}
        <div className='items-center justify-center'>
        {fruitsProducts.length < 1 && (
          <div className='container-fluid mdl:ml-[575px] ml-1 font-bold'>No Fruits Posted or Wait for Products to Show</div>
        )
        }
        </div>
      </div>
    </div>
  )
}

export default FruitsProduct