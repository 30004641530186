import React, {useEffect, useState} from 'react'
import "./ProfDropdown.css";
import { useDispatch } from 'react-redux';
import { userSignOut } from '../redux/farmlinkSlice';
import { getAuth, signOut } from "firebase/auth";
import { Link, useNavigate } from 'react-router-dom';
import { db } from '../firebase.config';

function ProfDropdown() {

    const navigate = useNavigate();
    const auth = getAuth();
    const dispatch = useDispatch();
    /* const user = auth.currentUser; */
    const [follow, setFollow] = useState(false);
    
    const handleLogout=()=>{
        signOut(auth).then(() => {
          //dispatch(userSignOut())
            navigate("/")
            window.location.reload();
          console.log("sign out successfully")
        }).catch((error) => {
          console.log(error)
        });
      }

     /*  useEffect(() => {
        if(auth.currentUser){
          let uid = auth.currentUser.uid;
  
        const unfollow = db.collection('user').doc(uid).collection('sellerInformation')
          .onSnapshot((snapshot) => {
            setFollow(!snapshot.empty)
          });
          return unfollow;
        }
          
      }, [auth]); */

      useEffect(() => {
        if(auth.currentUser){
        const unfollow = db.collection('user').doc(auth.currentUser.uid).collection('sellerInformation').where("status" , "==" , "Approved")
        .onSnapshot((doc) => {
          setFollow(!doc.empty);
          
        });
        return unfollow;
      }
      }, [auth.currentUser]);

  return <>
      
    <div className=''>
    <ul className='profile-submenu w-[150px] text-center absolute bg-black 
    cursor-pointer font-bold mdl:-ml-[70px] -ml-[65px] z-50 top-[28px]'>
    <ul>


    <Link to="/profile">
    <li className='myaccount'>My Account</li>
    </Link>
    <Link to="/myorders">
    <li className='mypurchase'>My Purchases</li>
    </Link>
    
    {/* <Link to="/shop">
    <li className='myshop'>My Shop</li>
    </Link> */}

    <Link to="/shop">
    {follow ? (<li className='myshop'>My Shop</li>) : (<div></div>)}
    </Link>
    </ul>
    
    <li className='logout' onClick={handleLogout}>Logout</li>
    </ul>
    </div>
    
    </>
}

export default ProfDropdown